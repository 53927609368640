import Vue from 'vue'
import Vuex from 'vuex'
import api from '../assets/js/api'
import { getCookie, checkCookie } from '../assets/js/cookieUtils'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //状态管理
    currentPatient: null,
    num: 10,
    start_num: 1,
    finish_num: 1,
    frame_num: 1,
    menuList: [],
  },
  mutations: {
    //修改state
    setCurrentPatientMut(state, params) {
      state.currentPatient = params
    },
    setFrameNum(state, num) {
      state.frame_num = num
    },
    setMenuList(state, val) {
      state.menuList = val
    },
  },
  actions: {
    //异步操作
    setCurrentPatientAct(context, operationId, useCache = true) {
      let { commit } = context
      if (useCache && checkCookie(operationId)) {
        let obj = getCookie(operationId)
        commit('setCurrentPatientMut', obj)
      } else {
        let url = `/v1/webconsole/study/operation/get/${operationId}`
        api.get(url).then((res) => {
          let obj = res.data.data
          commit('setCurrentPatientMut', obj)
        })
      }
    },
    setCurrentPatientActByPatientsID(context, patientsID, useCache = true) {
      let { commit } = context
      if (useCache && checkCookie(patientsID)) {
        let obj = getCookie(patientsID)
        commit('setCurrentPatientMut', obj)
      } else {
        let url = `/v1/webconsole/patientCenter/patient/patient_id/${patientsID}`
        api.get(url).then((res) => {
          let obj = res.data.data
          commit('setCurrentPatientMut', obj)
        })
      }
    }
  },
  getters: {
    //计算属性
  },
  modules: {
    //模块
  },
  plugins: [
    (store) => {
      // 从本地存储中读取数据并初始化 Vuex 状态
      const globalMenuListData = JSON.parse(localStorage.getItem('menuList'))
      if (globalMenuListData) {
        store.replaceState(Object.assign({}, store.state, { menuList: globalMenuListData }))
      }

      // 监听 Vuex 状态变化，将数据持久化到本地存储中
      store.subscribe((mutation, state) => {
        localStorage.setItem('menuList', JSON.stringify(state.menuList))
      })
    }
  ]
})
