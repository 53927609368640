export function dateFormat(date) {
  if (!date) return null
  let now = new Date()
  let year = now.getFullYear()
  let month = now.getMonth() + 1
  let day = now.getDate()
  if (month.toString().length === 1) {
    month = '0' + month
  }
  if (day.toString().length === 1) {
    day = '0' + day
  }
  let nowDate = year.toString() + '-' + month.toString() + '-' + day.toString()
  let nDate = date.substr(0, 10)
  const flag = compareDate(nDate, nowDate)
  if (flag === 0) {
    return '今天' + ' ' + date.substr(11, 5)
  } else if (flag === 1) {
    return '明天'
  } else if (flag === -1) {
    return '昨天'
  } else {
    return date.substr(0, 16)
  }
}

export function compareDate(date1, date2) {
  if (date1 === date2) return 0
  if (date1.substr(0, 4) === date2.substr(0, 4)) {
    if (date1.substr(5, 2) === date2.substr(5, 2)) {
      if (date1.substr(8, 2) === parseInt(date2.substr(8, 2)) - 1) {
        return -1
      } else if (date1.substr(8, 2) === parseInt(date2.substr(8, 2)) + 1) {
        return 1
      }
    }
  }
  return 2
}

export function to_birth(val) {
  if (val.length != 18) return this.$message.warning('请输入正确的身份证号')
  var info = val.substr(6, 8)
  var a = info.substr(0, 4)
  var b = info.substr(4, 2)
  var c = info.substr(6, 2)
  var time = a + '/' + b + '/' + c + ' ' + '00:00:00'

  var date = new Date(time)
  try {
    var y = date.getFullYear()
    var m = date.getMonth() + 1
    var d = date.getDate()
    var s = y + '-' + m + '-' + d
    return s
  } catch (e) {
    this.$message.warning('提取出生日期失败，请检查身份证号是否正确')
  }
}

export function base64ImgtoFile(dataurl, filename = 'file') {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const suffix = mime.split('/')[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  })
}

export function toDate(times) {
  if (!times) {
    return ''
  }
  let Y = times.getFullYear()
  let M = times.getMonth() + 1 < 10 ? '0' + (times.getMonth() + 1) : times.getMonth() + 1
  let D = times.getDate() < 10 ? '0' + times.getDate() : times.getDate()
  let h = times.getHours() < 10 ? '0' + times.getHours() : times.getHours()
  let m = times.getMinutes() < 10 ? '0' + times.getMinutes() : times.getMinutes()
  // let s = times.getSeconds() < 10 ? '0' + (times.getSeconds()) : times.getSeconds()
  return (times = Y + '-' + M + '-' + D + ' ' + h + ':' + m)
}

export function toDateDetail(times) {
  if (!times) {
    return ''
  }
  let Y = times.getFullYear()
  let M = times.getMonth() + 1 < 10 ? '0' + (times.getMonth() + 1) : times.getMonth() + 1
  let D = times.getDate() < 10 ? '0' + times.getDate() : times.getDate()
  let h = times.getHours() < 10 ? '0' + times.getHours() : times.getHours()
  let m = times.getMinutes() < 10 ? '0' + times.getMinutes() : times.getMinutes()
  let s = times.getSeconds() < 10 ? '0' + times.getSeconds() : times.getSeconds()
  return (times = Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s)
}

export function timestampToDate(timestamp) {
  if (!timestamp) {
    return ''
  }
  var date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  return Y + M + D
}

export function getHMSDate(str) {
  let times = new Date(str)
  let h = times.getHours() < 10 ? '0' + times.getHours() : times.getHours()
  let m = times.getMinutes() < 10 ? '0' + times.getMinutes() : times.getMinutes()
  // let s = times.getSeconds() < 10 ? '0' + (times.getSeconds()) : times.getSeconds();
  return h + ':' + m
}
export function todayDate(today) {
  let year = today.getFullYear()
  let month = today.getMonth() + 1
  let day = today.getDate()
  ///月份小于10,前面加0
  if (month < 10) {
    month = '0' + month
  }
  ///日期小于10,前面加0
  if (day < 10) {
    day = '0' + day
  }
  return year + '-' + month + '-' + day
}
// 获取昨天时间
export function getYesterDatDate(today) {
  var arrMonth = new Array(
    [0],
    [31],
    [28],
    [31],
    [30],
    [31],
    [30],
    [31],
    [31],
    [30],
    [31],
    [30],
    [31]
  ) //初始化月份(第一个0,占位作用,让下标和月份对应)
  var strYear = today.getFullYear() //获取年份
  var strDay = today.getDate() //获取日期
  var strMonth = today.getMonth() + 1 //获取月份
  ///判断是否是闰年
  if (strYear % 4 == 0 && strYear % 100 != 0) {
    arrMonth[2] = 29
  }
  ///判断日期是否是第一天
  if (strDay - 1 == 0) {
    ///判断月份是否是一月
    if (strMonth - 1 == 0) {
      strYear -= 1
      strMonth = 12
    } else {
      strMonth -= 1
    }
    strDay = arrMonth[strMonth]
  } else {
    strDay -= 1
  }
  ///月份小于10,前面加0
  if (strMonth < 10) {
    strMonth = '0' + strMonth
  }
  ///日期小于10,前面加0
  if (strDay < 10) {
    strDay = '0' + strDay
  }
  return strYear + '-' + strMonth + '-' + strDay
}
// 获取明天时间
export function getTomorrowDate(today) {
  var arrMonth = new Array(
    [0],
    [31],
    [28],
    [31],
    [30],
    [31],
    [30],
    [31],
    [31],
    [30],
    [31],
    [30],
    [31]
  ) //初始化月份(第一个0,占位作用,让下标和月份对应)
  var strYear = today.getFullYear() //获取年份
  var strDay = today.getDate() //获取日期
  var strMonth = today.getMonth() + 1 //获取月份
  ///判断是否是闰年
  if (strYear % 4 == 0 && strYear % 100 != 0) {
    arrMonth[2] = 29
  }
  ///判断日期是否是最后一天
  if ((strDay + 1) / arrMonth[strMonth] > 1) {
    if (strMonth == 12) {
      strMonth == 1
      strYear += 1
      strDay = 1
    } else {
      strMonth += 1
      strDay = 1
    }
  } else {
    strDay += 1
  }
  ///月份小于10,前面加0
  if (strMonth < 10) {
    strMonth = '0' + strMonth
  }
  ///日期小于10,前面加0
  if (strDay < 10) {
    strDay = '0' + strDay
  }
  return strYear + '-' + strMonth + '-' + strDay
}
// 获取最近一周
export function getRecentWeekDate(today) {
  var arrMonth = new Array(
    [0],
    [31],
    [28],
    [31],
    [30],
    [31],
    [30],
    [31],
    [31],
    [30],
    [31],
    [30],
    [31]
  ) //初始化月份(第一个0,占位作用,让下标和月份对应)
  var strYear = today.getFullYear() //获取年份
  var strDay = today.getDate() //获取日期
  var strMonth = today.getMonth() + 1 //获取月份
  ///判断是否是闰年
  if (strYear % 4 == 0 && strYear % 100 != 0) {
    arrMonth[2] = 29
  }
  ///判断日期是否是最后一天
  if (strDay - 7 <= 0) {
    if (strMonth - 1 == 0) {
      strYear -= 1
      strMonth = 12
      strDay = arrMonth[strMonth] - (7 - strDay)
    } else {
      strMonth -= 1
      strDay = arrMonth[strMonth] - (7 - strDay)
    }
  } else {
    strDay -= 7
  }
  if (strMonth < 10) {
    strMonth = '0' + strMonth
  }
  ///日期小于10,前面加0
  if (strDay < 10) {
    strDay = '0' + strDay
  }
  return strYear + '-' + strMonth + '-' + strDay
}
// 获取最近一月
export function getRecentMonthDate(today) {
  var arrMonth = new Array(
    [0],
    [31],
    [28],
    [31],
    [30],
    [31],
    [30],
    [31],
    [31],
    [30],
    [31],
    [30],
    [31]
  ) //初始化月份(第一个0,占位作用,让下标和月份对应)
  var strYear = today.getFullYear() //获取年份
  var strDay = today.getDate() //获取日期
  var strMonth = today.getMonth() + 1 //获取月份
  ///判断是否是闰年
  if (strYear % 4 == 0 && strYear % 100 != 0) {
    arrMonth[2] = 29
  }
  ///判断日期是否是一月
  if (strMonth - 1 == 0) {
    strMonth = 12
    strYear -= 1
  } else {
    strMonth -= 1
  }
  ///月份小于10,前面加0
  if (strMonth < 10) {
    strMonth = '0' + strMonth
  }
  ///日期小于10,前面加0
  if (strDay < 10) {
    strDay = '0' + strDay
  }
  return strYear + '-' + strMonth + '-' + strDay
}

export const baseUrl = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  }
}

export function formatUtcTime(v) {
  let date = new Date(v)
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
    '-' +
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
    ' ' +
    date.getHours() +
    ':' +
    date.getMinutes() +
    ':' +
    (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  )
}

export function getTimestamp(time) {
  let date1 = new Date(time)
  return date1.toLocaleDateString().replace(/\//g, '-') + ' ' + date1.toTimeString().substr(0, 5)
}

export function differMin(faultDate, completeTime) {
  var stime = Date.parse(new Date(faultDate.replace(/-/g, '/')))
  var etime = Date.parse(new Date(completeTime.replace(/-/g, '/')))
  // 两个时间戳相差的毫秒数
  var usedTime = etime - stime
  // 计算相差分钟数
  var minutes = Math.floor(usedTime / (60 * 1000))
  return minutes
}

export function differDDHHSS(faultDate, completeTime) {
  var stime = Date.parse(new Date(faultDate))
  var etime = Date.parse(new Date(completeTime))
  // 两个时间戳相差的毫秒数
  var usedTime = etime - stime
  // 计算相差的天数
  var days = Math.floor(usedTime / (24 * 3600 * 1000))
  // 计算天数后剩余的毫秒数
  var leave1 = usedTime % (24 * 3600 * 1000)
  // 计算出小时数
  var hours = Math.floor(leave1 / (3600 * 1000))
  // 计算小时数后剩余的毫秒数
  var leave2 = leave1 % (3600 * 1000)
  // 计算相差分钟数
  var minutes = Math.floor(leave2 / (60 * 1000))
  var time = days + '天' + hours + '时' + minutes + '分'
  return time
}

export function isEqual(first, second) {
  if (!first || !second) return false
  const arr1 = Object.keys(first)
  const arr2 = Object.keys(second)
  if (arr1.length != arr2.length) return false
  for (let k in first) {
    if (typeof first[k] == 'object' || typeof second[k] == 'object') {
      if (isEqual(first[k], second[k])) return false
    } else if (first[k] !== second[k]) return false
  }
  return true
}

export function uniqueJsonArrByField(jsonArr, field) {
  // 数组长度小于2 或 没有指定去重字段 或 不是json格式数据
  if (jsonArr.length < 2 || !field || typeof jsonArr[0] !== 'object') return jsonArr
  const res = new Map() // 标识对象
  const uniqueArr = jsonArr.filter((item) => !res.has(item[field]) && res.set(item[field], true))
  return uniqueArr
}
// 返回前number天的日期格式为2020-02-02，参数number为前几天
export function getBeforeDate(number) {
  const num = number
  const date = new Date()
  let year = date.getFullYear()
  let mon = date.getMonth() + 1
  let day = date.getDate()
  if (day <= num) {
    if (mon > 1) {
      mon = mon - 1
    } else {
      year = year - 1
      mon = 12
    }
  }
  date.setDate(date.getDate() - num)
  year = date.getFullYear()
  mon = date.getMonth() + 1
  day = date.getDate()
  const s = year + '-' + (mon < 10 ? '0' + mon : mon) + '-' + (day < 10 ? '0' + day : day)
  return s
}

export function getAfterDate(number) {
  const num = number
  const date = new Date()
  let year = date.getFullYear()
  let mon = date.getMonth() + 1
  let day = date.getDate()
  if (day <= num) {
    if (mon > 1) {
      mon = mon - 1
    } else {
      year = year - 1
      mon = 12
    }
  }
  date.setDate(date.getDate() + num)
  year = date.getFullYear()
  mon = date.getMonth() + 1
  day = date.getDate()
  const s = year + '-' + (mon < 10 ? '0' + mon : mon) + '-' + (day < 10 ? '0' + day : day)
  return s
}

export function getLatestExamineData(data) {
  // 当前日期
  const currentDate = new Date()

  // 转换后的数据
  const parsedData = data

  // 用于存储结果的数组
  const results = []

  // 遍历数据进行处理
  parsedData.forEach((record) => {
    const { examineDate, items } = record

    // 获取 examineDate 的日期对象
    const recordDate = new Date(examineDate)

    // 遍历 items
    items.forEach((aliasObj) => {
      const { itemTitle, _isSecect, itemValue, elementId } = aliasObj

      // 当 isSelect 为 true 时进行处理
      if (_isSecect) {
        const existingResultIndex = results.findIndex((result) => result.itemTitle === itemTitle)

        // 如果结果数组中不存在该 alias，则直接将当前值添加到结果数组中
        if (existingResultIndex === -1) {
          results.push({
            itemTitle,
            itemValue: itemValue || null, // 初始化为 null
            elementId: elementId || null, // 初始化为 null
            examineDate: itemValue ? recordDate : null // 初始化为 null
          })
        } else {
          const existingResult = results[existingResultIndex]

          // 如果当前日期更接近于当前日期且 itemValue 有值，则更新结果数组中的值
          if (
            !existingResult.itemValue &&
            itemValue && // 当前结果中的 itemValue 为空且当前 itemValue 有值
            (!existingResult.examineDate ||
              Math.abs(currentDate - existingResult.examineDate) >
                Math.abs(currentDate - recordDate)) // 当前结果中的日期为空或当前日期更接近
          ) {
            results[existingResultIndex] = {
              itemTitle,
              itemValue,
              elementId,
              examineDate: recordDate
            }
          }
        }
      }
    })
  })
  return results
}
// 将数组数据转成后端要的数据结构
export const arrDataTransform = (name, data) => {
  return {
    elementId: name,
    value: null,
    extendTable:
      data?.length > 0
        ? data.map((v) => {
            delete v.id
            let arr = []
            for (let i in v) {
              arr.push({
                elementId: i,
                value: v[i],
                extendTable: null
              })
            }
            return arr
          })
        : null
  }
}
// 将对象数据转成后端要的数据结构
export const objDataTransform = (name, data) => {
  return {
    elementId: name,
    value: null,
    extendTable: [
      Object.keys(data).map((v) => ({
        elementId: v,
        value: data[v],
        extendTable: null
      }))
    ]
  }
}

export const getFormatDate = (date) => {
  const currentDate = date
  const year = currentDate.getFullYear()
  const month =
    currentDate.getMonth() + 1 > 9 ? currentDate.getMonth() + 1 : '0' + (currentDate.getMonth() + 1)
  const day = currentDate.getDate() > 9 ? currentDate.getDate() : '0' + currentDate.getDate()
  const newDate = year + '-' + month + '-' + day
  return newDate
}

export const calculateAgeFromDays = (days) => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  const birthDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000)
  const birthYear = birthDate.getFullYear()

  let age = currentYear - birthYear

  // 检查生日是否已经过了，如果还没过，则减去一年
  if (currentDate < new Date(birthDate.setFullYear(currentYear))) {
    age--
  }

  if (age < 1) {
    return days + '天'
  } else if (age < 2) {
    const remainingDays = Math.floor((currentDate - birthDate) / (24 * 60 * 60 * 1000))
    return '1年零' + remainingDays + '天'
  } else {
    return age + '岁'
  }
}
