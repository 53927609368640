import axios from '@/plugins/axios'
import { SERVER_HOST } from '@/utils/globalVariable'

export default {
  get(url, val) {
    if (url.includes('http'))
      return axios({
        method: 'get',
        url: url,
        params: val,
      })

    return axios({
      method: 'get',
      url: SERVER_HOST + url,
      params: val,
    })
  },

  post(url, val) {
    if (url.includes('http'))
      return axios({
        method: 'post',
        url: url,
        data: val,
      })

    return axios({
      method: 'post',
      url: SERVER_HOST + url,
      data: val,
    })
  },
  put(url, val) {
    if (url.includes('http'))
      return axios({
        method: 'put',
        url: url,
        data: val,
      })

    return axios({
      method: 'put',
      url: SERVER_HOST + url,
      data: val,
    })
  },
  delete(url, val) {
    if (url.includes('http'))
      return axios({
        method: 'delete',
        url: url,
        params: val,
      })

    return axios({
      method: 'delete',
      url: SERVER_HOST + url,
      params: val,
    })
  },
  deleteObj(url, val) {
    if (url.includes('http'))
      return axios({
        method: 'delete',
        url: url,
        data: val,
      })

    return axios({
      method: 'delete',
      url: SERVER_HOST + url,
      data: val,
    })
  },
}
