import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    meta: {
      title: '手术管理',
      isShow: true
    }
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/Report',
    name: 'followReport',
    component: () => import('../views/Follow/report/index.vue'),
    meta: {
      title: '统计报表',
      isShow: true
    }
  },
  {
    path: '/QualityReport',
    name: 'QualityReport',
    component: () => import('../views/QualityReport/index.vue'),
    meta: {
      title: '质控报表',
      isShow: true
    }
  },
  {
    path: '/AdvancedSearch',
    name: 'AdvancedSearch',
    component: () => import('../views/AdvancedSearch/index.vue'),
    meta: {
      title: '高级搜索',
      isShow: true
    }
  },
  {
    path: '/Voyager',
    name: 'Voyager',
    component: () => import('../views/Voyager/index.vue'),
    meta: {
      title: '数据分析和探索',
      isShow: true
    }
  },
  {
    path: '/Treatment',
    name: 'Treatment',
    component: () => import('../views/Solution/Treatment/index.vue'),
    meta: {
      title: '治疗方案'
    },
    children: [
      {
        path: 'Info',
        name: 'Info-Treatment',
        component: () =>
          import('../views/Solution/DetailSolution/components/solution-Info/index.vue'),
        meta: {
          title: '方案概述'
        }
      },
      {
        path: 'Details',
        name: 'Details-Treatment',
        component: () =>
          import('../views/Solution/DetailSolution/components/solution-Details/index.vue'),
        meta: {
          title: '基本情况'
        }
      },
      {
        path: 'Check',
        name: 'Check-Treatment',
        component: () => import('../views/Solution/DetailSolution/components/Check/index.vue'),
        meta: {
          title: '基线'
        }
      },
      {
        path: 'Inspection',
        name: 'Inspection-Treatment',
        component: () =>
          import('../views/Solution/DetailSolution/components/solution-Inspection/index.vue'),
        meta: {
          title: '检查检验'
        }
      },
      {
        path: 'FormHisInspection',
        name: 'FormHisInspection-Treatment',
        component: () =>
          import(
            '../views/Solution/DetailSolution/components/solution-FormHisInspection/index.vue'
          ),
        meta: {
          title: '基线检查检验'
        }
      },
      {
        path: 'HisInspection',
        name: 'HisInspection-Treatment',
        component: () =>
          import('../views/Solution/DetailSolution/components/solution-HisInspection/index.vue'),
        meta: {
          title: '历史检查检验'
        }
      },
      {
        path: 'PacsInspection',
        name: 'PacsInspection-Treatment',
        component: () =>
          import('../views/Solution/DetailSolution/components/solution-PacsInspection/index.vue'),
        meta: {
          title: 'Pacs智能比对'
        }
      },
      {
        path: 'Dicom',
        name: 'Dicom-Treatment',
        component: () => import('../views/Solution/DetailSolution/components/Dicom/index.vue'),
        meta: {
          title: 'PACS影像'
        }
      },
      {
        path: 'Disposal',
        name: 'Disposal-Treatment',
        component: () =>
          import('../views/Solution/DetailSolution/components/solution-Disposal/index.vue'),
        meta: {
          title: '拟治疗措施'
        }
      },
      {
        path: 'Evaluate',
        name: 'Evaluate-Treatment',
        component: () =>
          import('../views/Solution/DetailSolution/components/solution-Evaluate/index.vue'),
        meta: {
          title: '评价'
        }
      },
      {
        path: 'Medication',
        name: 'Medication-Treatment',
        component: () => import('../views/Solution/DetailSolution/components/Medication/index.vue'),
        meta: {
          title: '医嘱'
        }
      },
      {
        path: 'Progression',
        name: 'Progression-Treatment',
        component: () =>
          import('../views/Solution/DetailSolution/components/solution-Progression/index.vue'),
        meta: {
          title: '病情进展'
        }
      },
      {
        path: 'AdverseEvents',
        name: 'AdverseEvents-Treatment',
        component: () =>
          import('../views/Solution/DetailSolution/components/AdverseEvents/index.vue'),
        meta: {
          title: '不良事件'
        }
      },
      {
        path: 'DeathEvents',
        name: 'DeathEvents-Treatment',
        component: () =>
          import('../views/Solution/DetailSolution/components/DeathEvents/index.vue'),
        meta: {
          title: '死亡事件'
        }
      },
      {
        path: 'Follow',
        name: 'Follow-Treatment',
        component: () => import('../views/Solution/DetailSolution/components/Follow/index.vue'),
        meta: {
          title: '随访'
        }
      }
    ]
  },
  {
    path: '/Detail',
    name: 'Detail',
    component: () => import('../components/Home.vue'),
    meta: {
      title: '手术管理'
    },
    children: [
      {
        // 手术基本信息
        path: 'BaseInfo',
        name: 'BaseInfo',
        component: () => import('../views/Menu/menu-BaseInfo/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 术前评估
        path: 'PreAssessment',
        name: 'PreAssessment-SQPG',
        component: () => import('../views/Menu/menu-Assessment/Index.vue'),
        meta: {
          meta: 'SQPG',
          title: '手术管理'
        }
      },
      {
        // 术后评估
        path: 'PostAssessment',
        name: 'PostAssessment-SHPG',
        component: () => import('../views/Menu/menu-Assessment/Index.vue'),
        meta: {
          meta: 'SHPG',
          title: '手术管理'
        }
      },
      {
        // 手术交接
        path: 'NurseIntervenes',
        name: 'NurseIntervenes',
        component: () => import('../views/Menu/menu-NurseIntervenes/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 介入护理
        path: 'Nursing',
        name: 'Nursing',
        component: () => import('../views/Menu/menu-Nursing/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 用药记录
        path: 'Medicate',
        name: 'Medicate',
        component: () => import('../views/Menu/menu-Medicate/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 耗材
        path: 'Equipment',
        name: 'Equipment',
        component: () => import('../views/Menu/menu-Consumables/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 冠脉造影
        path: 'CoronaryInfo',
        name: 'CoronaryInfo',
        component: () => import('../views/Menu/menu-CoronaryAngiography/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 起搏器
        path: 'Pacemaker',
        name: 'Pacemaker',
        component: () => import('../views/Menu/menu-Pacemaker/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 电生理
        path: 'Electrophysiology',
        name: 'Electrophysiology',
        component: () => import('../views/Menu/menu-Electrophysiology/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 瓣膜介入
        path: 'AorticInfo',
        name: 'AorticInfo',
        component: () => import('../views/Menu/menu-ValveIntervention/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 肝癌介入
        path: 'LiverCancer',
        name: 'LiverCancer',
        component: () => import('../views/Menu/menu-LiverCancerIntervention/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        path: 'SurgicalSafetyCheck',
        name: 'SurgicalSafetyCheck',
        component: () => import('../views/Menu/menu-Assessment/Index.vue'),
        meta: {
          meta: 'AQJC',
          title: '手术管理'
        }
      },
      {
        // 手术前检查检验
        path: 'PreCheck',
        name: 'PreCheck',
        component: () => import('../views/Menu/menu-Assessment-SQJC/Index.vue'),
        meta: {
          meta: 'SQJC',
          title: '手术管理'
        }
      },
      {
        path: 'BadEvent',
        name: 'BadEvent',
        component: () => import('../views/Menu/menu-Assessment/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 神经动脉瘤
        path: 'NerveAneurysm',
        name: 'NerveAneurysm',
        component: () => import('../views/Menu/menu-Aneurysm/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 动静脉畸形
        path: 'NerveAvm',
        name: 'NerveAvm',
        component: () => import('../views/Menu/menu-ArteriovenousMalformations/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 动脉狭窄
        path: 'NerveArteryStenosis',
        name: 'NerveArteryStenosis',
        component: () => import('../views/Menu/menu-ArterialStenosis/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 颈动脉狭窄
        path: 'CarotidStenosis',
        name: 'CarotidStenosis',
        component: () => import('../views/Menu/menu-CarotidStenosis/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // CCF
        path: 'NerveCcf',
        name: 'NerveCcf',
        component: () => import('../views/Menu/menu-CCF/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // DAVF
        path: 'NerveDavf',
        name: 'NerveDavf',
        component: () => import('../views/Menu/menu-DAVF/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 脑栓塞
        path: 'NerveCe',
        name: 'NerveCe',
        component: () => import('../views/Menu/menu-CerebralEmbolism/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },

      {
        // 肝硬化门静脉高压
        path: 'Cirrhosis',
        name: 'Cirrhosis',
        component: () => import('../views/Menu/menu-CirrhosisWithPortalHypertension/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 外周血管介入
        path: 'PeripheralVessels',
        name: 'PeripheralVessels',
        component: () => import('../views/Menu/menu-PeripheralVascularIntervention/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 主动脉夹层
        path: 'AorticDissection',
        name: 'AorticDissection',
        component: () => import('../views/Menu/menu-AorticDissection/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 主动脉瘤
        path: 'AorticAneurysm',
        name: 'AorticAneurysm',
        component: () => import('../views/Menu/menu-aorticAneurysm/index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        //二尖瓣
        path: 'Mitral',
        name: 'Mitral',
        component: () => import('../views/Menu/menu-MitralTricuspid/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        // 通用模块
        path: 'CommonModule',
        name: 'CommonModule',
        component: () => import('../views/Menu/menu-GeneralModule/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        path: 'Pacs',
        name: 'Pacs',
        component: () => import('../views/Menu/menu-ImageProcess/Index.vue'),
        meta: {
          title: '手术管理'
        }
      },
      {
        path: 'ReportPrint',
        name: 'ReportPrint',
        component: () => import('../views/Menu/menu-SignaturePrint/Index.vue'),
        meta: {
          title: '手术管理'
        }
      }
    ]
  },
  //影像记录
  {
    path: '/DicomPlayer',
    name: 'DicomPlayer',
    component: () => import('../views/Menu/menu-DicomPlayer/Index.vue')
  },
  {
    path: '/Database',
    name: '/Database',
    component: () => import('../views/QueryStatistics/Complex_database.vue'),
    meta: {
      title: '专病数据库',
      isShow: true
    }
  },
  {
    path: '/Database/StatisticalInfo',
    name: 'StatisticalInfo',
    component: () => import('../views/QueryStatistics/databaseInfo.vue'),
    meta: {
      title: '专病数据库',
      isShow: true
    }
  },
  {
    path: '/OperationRoom',
    name: 'OperationRoom',
    component: () => import('../components/OperationRoom.vue'),
    meta: {
      title: '手术排程',
      isShow: true
    }
  },
  // 治疗方案
  {
    path: '/Solution',
    name: 'Solution',
    component: () => import('../views/Solution/index.vue'),
    meta: {
      title: '治疗方案',
      isShow: true
    }
  },
  // 队列研究
  {
    path: '/ResearchQueue',
    name: 'ResearchQueue',
    component: () => import('../views/ResearchQueue/index.vue'),
    meta: {
      title: '队列研究',
      isShow: true
    }
  },
  {
    path: '/FollowNav',
    name: 'FollowNav',
    component: () => import('../components/FollowNav.vue'),
    meta: {
      title: '随访导航',
      isShow: true
    }
  },
  {
    path: '/FollowHome',
    name: 'FollowHome',
    component: () => import('../views/Follow/index.vue'),
    meta: {
      title: '随访管理',
      isShow: true
    }
  },
  {
    path: '/Follow',
    name: 'Follow',
    component: () => import('../components/FollowHome.vue'),
    meta: {
      title: '专病数据库'
    },
    children: [
      {
        path: 'follow',
        name: 'followRecord',
        component: () => import('../views/Follow/follow-Base/index.vue'),
        meta: {
          title: '专病数据库'
        }
      },
      {
        path: 'BaseLine',
        name: 'BaseLine',
        component: () => import('../views/Follow/follow-BaseLine/index.vue'),
        meta: {
          title: '专病数据库'
        }
      },
      {
        path: 'LeaveHospital',
        name: 'LeaveHospital',
        component: () => import('../views/Follow/follow-LeaveHospital/index.vue'),
        meta: {
          title: '专病数据库'
        }
      },
      {
        path: 'Comparison',
        name: 'Comparison',
        component: () =>
          import('../views/Follow/follow-LeaveHospital/components/InspectionRecords.vue'),
        meta: {
          title: '出院前比对'
        }
      },
      {
        path: 'LeaveHospitalBaseInspectionRecords',
        name: 'LeaveHospital-BaseInspectionRecords',
        component: () =>
          import('../views/Follow/follow-LeaveHospital/components/BaseInspectionRecords.vue'),
        meta: {
          title: '出院前基线比对'
        }
      },
      {
        path: 'followComparison',
        name: 'followComparison',
        component: () => import('../views/Follow/follow-Base/components/InspectionRecords.vue'),
        meta: {
          title: '随访记录比对'
        }
      },
      {
        path: 'Inspection',
        name: 'Inspection',
        component: () => import('../views/Follow/follow-Inspection/index.vue'),
        meta: {
          title: '检查检验'
        }
      },
      {
        path: 'Dicom',
        name: 'Dicom',
        component: () => import('../views/Follow/follow-Dicom/index.vue'),
        meta: {
          title: 'PACS影像'
        }
      },
      {
        path: 'HisInspection',
        name: 'HisInspection-follow',
        component: () =>
          import('../views/Solution/DetailSolution/components/solution-HisInspection/index.vue'),
        meta: {
          title: '智能比对'
        }
      },
      {
        path: 'PacsInspection',
        name: 'PacsInspection-follow',
        component: () =>
          import('../views/Solution/DetailSolution/components/solution-PacsInspection/index.vue'),
        meta: {
          title: 'Pacs智能比对'
        }
      },
      {
        path: 'PreExaminationInspectionRecords',
        name: 'PreExamination-InspectionRecords',
        component: () => import('../views/Follow/preExamination/components/InspectionRecords.vue'),
        meta: {
          title: '术前检查基线比对'
        }
      },
      {
        path: 'PreExamination',
        name: 'PreExamination',
        component: () => import('../views/Follow/preExamination/index.vue'),
        meta: {
          title: '专病数据库'
        }
      },
      {
        path: 'DeathEvent',
        name: 'DeathEvent',
        component: () => import('../views/Follow/deathEvent/index.vue'),
        meta: {
          title: '专病数据库'
        }
      },
      {
        path: 'PreAssessment',
        name: 'PreAssessment-follow',
        component: () => import('../views/Follow/preAssessment/index.vue'),
        meta: {
          title: '专病数据库'
        }
      },
      {
        path: 'Aneurysm',
        name: 'Aneurysm-follow',
        component: () => import('../views/Menu/menu-Aneurysm/Index.vue'),
        meta: {
          title: '专病数据库'
        }
      },
      {
        path: 'Info',
        name: 'Info-follow',
        component: () => import('../views/Follow/follow-menu/index.vue'),
        meta: {
          title: '专病数据库'
        },
        children: [
          {
            path: 'NerveAneurysm',
            name: 'NerveAneurysm-follow',
            component: () => import('../views/Menu/menu-Aneurysm/Index.vue')
          },
          {
            path: 'NerveAvm',
            name: 'NerveAvm-follow',
            component: () => import('../views/Menu/menu-ArteriovenousMalformations/Index.vue')
          },
          {
            path: 'NerveArteryStenosis',
            name: 'NerveArteryStenosis-follow',
            component: () => import('../views/Menu/menu-ArterialStenosis/Index.vue')
          },
          {
            // 颈动脉狭窄
            path: 'CarotidStenosis',
            name: 'CarotidStenosis-follow',
            component: () => import('../views/Menu/menu-CarotidStenosis/Index.vue')
          },
          {
            path: 'NerveCe',
            name: 'NerveCe-follow',
            component: () => import('../views/Menu/menu-CerebralEmbolism/Index.vue')
          },
          {
            path: 'NerveDavf',
            name: 'NerveDavf-follow',
            component: () => import('../views/Menu/menu-DAVF/Index.vue')
          },
          {
            path: 'NerveCcf',
            name: 'NerveCcf-follow',
            component: () => import('../views/Menu/menu-CCF/Index.vue')
          },
          {
            path: 'NurseIntervenes',
            name: 'NurseIntervenes-follow',
            component: () => import('../views/Menu/menu-NurseIntervenes/Index.vue')
          },
          {
            path: 'Medicate',
            name: 'Medicate-follow',
            component: () => import('../views/Menu/menu-Medicate/Index.vue')
          },
          {
            path: 'Nursing',
            name: 'Nursing-follow',
            component: () => import('../views/Menu/menu-Nursing/Index.vue'),
            meta: {
              noEdit: true
            }
          },
          {
            path: 'Equipment',
            name: 'Equipment-follow',
            component: () => import('../views/Menu/menu-Consumables/Index.vue'),
            meta: {
              noEdit: true
            }
          },
          {
            path: 'ReportPrint',
            name: 'ReportPrint-follow',
            component: () => import('../views/Menu/menu-SignaturePrint/Index.vue'),
            meta: {
              noEdit: true
            }
          },
          {
            path: 'Pacemaker',
            name: 'Pacemaker-follow',
            component: () => import('../views/Menu/menu-Pacemaker/Index.vue')
          },
          {
            path: 'AorticInfo',
            name: 'AorticInfo-follow',
            component: () => import('../views/Menu/menu-ValveIntervention/Index.vue'),
            meta: {
              isReadonly: true
            }
          },
          {
            // 肝癌介入
            path: 'LiverCancer',
            name: 'LiverCancer-follow',
            component: () => import('../views/Menu/menu-LiverCancerIntervention/Index.vue')
          },
          {
            // 肝硬化门静脉高压
            path: 'Cirrhosis',
            name: 'Cirrhosis-follow',
            component: () => import('../views/Menu/menu-CirrhosisWithPortalHypertension/Index.vue')
          },
          {
            // 外周血管介入
            path: 'PeripheralVessels',
            name: 'PeripheralVessels-follow',
            component: () => import('../views/Menu/menu-PeripheralVascularIntervention/Index.vue')
          },
          {
            // 主动脉夹层
            path: 'AorticDissection',
            name: 'AorticDissection-follow',
            component: () => import('../views/Menu/menu-AorticDissection/Index.vue')
          },
          {
            // 主动脉瘤
            path: 'AorticAneurysm',
            name: 'AorticAneurysm-follow',
            component: () => import('../views/Menu/menu-aorticAneurysm/index.vue')
          },
          //二尖瓣
          {
            path: 'Mitral',
            name: 'Mitral-follow',
            component: () => import('../views/Menu/menu-MitralTricuspid/Index.vue')
          },
          {
            path: 'Electrophysiology',
            name: 'Electrophysiology-follow',
            component: () => import('../views/Menu/menu-Electrophysiology/Index.vue')
          },
          {
            path: 'CoronaryInfo',
            name: 'CoronaryInfo-follow',
            component: () => import('../views/Menu/menu-CoronaryAngiography/Index.vue')
          },
          {
            path: 'Pacs',
            name: 'Pacs-follow',
            component: () => import('../views/Menu/menu-ImageProcess/Index.vue'),
            meta: {
              noEdit: true
            }
          },
          {
            // 通用模块
            path: 'CommonModule',
            name: 'CommonModule-follow',
            component: () => import('../views/Menu/menu-GeneralModule/Index.vue')
          }
        ]
      }
    ]
  }
]

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let docTitle = localStorage.getItem('system_title')
  let titleName = JSON.parse(localStorage.getItem('patientInfo'))
  if (to.meta.title) {
    if (to.meta.isShow) {
      document.title = `${to.meta.title} - ${docTitle}`
    } else {
      document.title = `${
        titleName ? titleName.patientsName || titleName.patientName : '数字介入中心'
      } - ${to.meta.title}`
    }
  } else {
    document.title = docTitle ? docTitle : '数字介入中心' // 此处写默认的title
  }
  if (to.path == '/') return next()
  var userCode = localStorage.getItem('userCode')
  if (from.path == '/' && to.path == '/Home' && userCode == undefined) {
    return next('/')
  } else return next()
})

export default router
