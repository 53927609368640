<style lang="less">
.home {
  .surgery-show {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 300px);
    .surgery-show-content {
      .el-pagination {
        text-align: center;
      }
      .el-table {
        border-left: none;
        border-right: none;
      }
    }
  }

  .header-second {
    height: 45px;
    display: flex;
    box-shadow: 0px 2px 2px #ccc;
    margin-bottom: 15px;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    .second-left {
      display: flex;
      align-items: center;
      .btn .el-button {
        font-size: 14px;
        .icon-rusuodengji:before {
          margin-right: 3px;
        }
        .icon-yuntongbu::before {
          margin-right: 3px;
        }
      }
    }
    .second-right {
      .btn .el-button {
        font-size: 14px;
      }
    }
  }
  .surgery-search-conditions {
    margin-bottom: 15px;
    .input-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .input-form-item {
        min-width: 28%;
        margin: 0.4rem 1.5rem;
        display: flex;
        align-items: center;

        .el-date-editor .el-range__icon {
          line-height: 19px;
        }
        .el-date-editor .el-range-separator {
          line-height: 19px;
        }
        .el-date-editor .el-range__close-icon {
          line-height: 19px;
        }
      }

      .input-form-item-label {
        text-align: center;
        width: 120px;
      }

      .input-form-item-content {
        width: 200px;
        & .el-select {
          width: 200px;
        }
      }
    }
  }
  .surgery-status {
    position: relative;
    height: 60%;
    .surgery-total {
      position: absolute;
      right: 50px;
      top: 4px;
      font-size: 15px;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
      color: red;
      font-size: 14px;
    }
    .status-noSchedule {
      color: red;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.examineNameListCenter {
  display: flex;
  justify-content: center;
}
</style>

<template>
  <div class="home">
    <IndexHeader />
    <!-- 手术管理 -->
    <div class="header-second">
      <div class="second-left">
        <div class="btn">
          <el-button
            v-if="syncBtnIsShow"
            size="mini"
            type="primary"
            icon="iconfont icon-yuntongbu"
            class="commonBtn"
            @click="confirm_information"
          >
            手术登记信息同步
          </el-button>
          <el-button
            v-if="simpleBtnIsShow"
            type="primary"
            size="mini"
            v-debounce="dialogInfo"
            icon="iconfont icon-rusuodengji"
            class="commonBtn"
          >
            手术登记
          </el-button>
          <el-button
            v-if="detailsBtnIsShow"
            type="primary"
            size="mini"
            v-debounce="dialogDetailsInfo"
            icon="iconfont icon-rusuodengji"
            class="commonBtn"
          >
            手术申请
          </el-button>
          <el-button
            type="primary"
            size="mini"
            v-debounce="clearTable"
            icon="el-icon-refresh-left"
            class="commonBtn"
          >
            清空
          </el-button>
          <el-button
            type="primary"
            size="mini"
            v-debounce="search"
            icon="el-icon-search"
            class="commonBtn"
          >
            查询
          </el-button>
        </div>
      </div>
      <div class="second-right">
        <div class="btn">
          <el-button
            type="primary"
            size="mini"
            v-debounce="report"
            icon="el-icon-tickets"
            class="commonBtn"
          >
            统计报表
          </el-button>
        </div>
      </div>
    </div>

    <div class="surgery-search-conditions">
      <div class="input-form">
        <div class="input-form-item">
          <div class="input-form-item-label">手术日期</div>
          <el-select
            class="input-form-item-content"
            v-model="surgeryDate"
            placeholder="请选择日期"
            @change="dateManuPick"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-dialog
          v-dialogDrag
          title="自定义时间选择"
          :visible.sync="time_dialogVisible"
          width="30%"
          :modal="false"
          :close-on-click-modal="false"
          :show-close="false"
          class="time_select"
        >
          <div class="input-form-item" style="margin-bottom: 20px">
            <div class="input-form-item-label">开始时间</div>
            <div class="input-form-item-content">
              <el-date-picker
                v-model="CISReportInfo.studyStartDate"
                type="date"
                size="mini"
                align="center"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
              ></el-date-picker>
            </div>
          </div>
          <div class="input-form-item" style="margin-bottom: 20px">
            <div class="input-form-item-label">结束时间</div>
            <div class="input-form-item-content">
              <el-date-picker
                v-model="CISReportInfo.studyEndDate"
                type="date"
                size="mini"
                placeholder="选择日期时间"
                align="center"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
              ></el-date-picker>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancel_time" size="mini">取 消</el-button>
            <el-button type="primary" @click="confirm_time" size="mini">确 定</el-button>
          </span>
        </el-dialog>

        <div class="input-form-item">
          <div class="input-form-item-label">手术名称</div>
          <div class="input-form-item-content">
            <el-select
              v-model="CISReportInfo.operationName"
              :clearable="true"
              filterable
              remote
              reserve-keyword
              :remote-method="remoteMethod"
              placeholder="请选择"
              :loading="surgeryNameLoading"
              @change="changeSearchSurgeryName"
            >
              <el-option
                v-for="(item, index) in pubOperationList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">病人姓名</div>
          <div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="string"
              clearable
              v-model="CISReportInfo.patientsName"
              @change="changeSearch"
              size="mini"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="input-form">
        <div class="input-form-item">
          <div class="input-form-item-label">病案号</div>
          <div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="string"
              clearable
              v-model="CISReportInfo.patientsID"
              @change="changeSearch"
              size="mini"
            ></el-input>
          </div>
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">科室</div>
          <div class="input-form-item-content">
            <el-select
              v-model="CISReportInfo.deptName"
              @change="changeSearch"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in sickAreaList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </div>
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">手术医生</div>
          <div class="input-form-item-content">
            <el-select
              v-model="CISReportInfo.examineDoctor"
              placeholder="请选择"
              clearable
              @change="changeSearch"
            >
              <el-option
                v-for="item in doctorList"
                :key="item.userId"
                :label="item.userName"
                :value="item.userName"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>

      <!-- <wait-study-dialog ref="waitStudyDialog" @save="dialogSave"></wait-study-dialog> -->
      <scheduling-dialog ref="SchedulingDialog" @save="dialogSave"></scheduling-dialog>
      <patient-info-input-dialog
        ref="patientInfoInputDialog"
        :doctorList="doctorList"
        :isToDetailPage="isToDetailPage"
        @save="dialogSave"
      ></patient-info-input-dialog>
      <patient-info-dialog
        ref="patientInfoDialog"
        :doctorList="doctorList"
        @save="dialogSave"
      ></patient-info-dialog>
    </div>
    <div class="surgery-status">
      <el-tabs v-model="tabs_index" type="card" @tab-click="handleClick">
        <el-tab-pane label="所有" name="0">
          <div class="surgery-show">
            <div class="surgery-show-content">
              <el-table
                :data="tableData"
                border
                style="width: 100%"
                :height="tableHeight"
                @sort-change="sortChange"
                :header-cell-class-name="handleHeaderClass"
              >
                <el-table-column align="center" type="index" width="30"></el-table-column>
                <el-table-column align="center" prop="studyDate" label="手术时间" width="180">
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.status == '待排程'"
                      @click="toSchedule(scope.row)"
                      class="status-noSchedule"
                    >
                      安排时间
                    </span>
                    <span v-else>{{ dateFormat(scope.row.studyDate) }}</span>
                  </template>
                </el-table-column>

                <el-table-column align="center" label="患者姓名" width="80" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-button @click="checkPatient(scope.row)" type="text" size="small">
                      {{ scope.row.patientsName }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsSex"
                  label="性别"
                  width="80"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsAge"
                  label="年龄"
                  width="80"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.ageDays">
                      {{ calculateAgeFromDays(scope.row.ageDays) }}
                    </span>
                    <span v-else>
                      {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="fileNumber"
                  label="库号"
                  width="120"
                ></el-table-column>
                <!-- <el-table-column
                  align="center"
                  prop="hisNo"
                  label="住院号"
                  width="150"
                ></el-table-column> -->
                <el-table-column
                  align="center"
                  prop="patientsID"
                  label="病案号"
                  width="150"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="ward"
                  label="病区"
                  width="120"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="bedNo"
                  label="床位号"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="importantItem"
                  label="重要事项"
                  width="200"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="clinicDiagnose"
                  label="术前诊断"
                  width="200"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="examineNameList"
                  label="手术名称"
                  min-width="250"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div class="examineNameListCenter">
                      <el-tag
                        v-for="(item, index) in scope.row.examineNameList"
                        :key="'examineName' + index"
                        style="margin-right: 5px"
                        type="info"
                        size="small"
                      >
                        {{ item }}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="examineDoctor"
                  label="手术医生"
                  width="100"
                  sortable="custom"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="lab"
                  label="手术室"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="studyApplyTime"
                  label="登记日期"
                  width="120"
                  sortable="custom"
                >
                  <template slot-scope="scope" v-if="scope.row.studyApplyTime">
                    <span>{{ scope.row.studyApplyTime.substr(0, 10) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="status"
                  label="手术状态"
                  :formatter="get_status"
                  width="100"
                ></el-table-column>
              </el-table>
              <!-- 分页区域 -->
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="userInfo_all.pagenum"
                :page-sizes="[15, 30, 50]"
                :page-size="userInfo_all.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="userInfo_all.total"
              ></el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="待排程" name="1">
          <div class="surgery-show">
            <div class="surgery-show-content">
              <el-table
                :data="waitTableData"
                border
                style="width: 100%"
                stripe
                :height="tableHeight"
                highlight-current-row
                :default-sort="{ prop: 'studyDate', order: 'descending' }"
                @sort-change="sortChange"
                :header-cell-class-name="handleHeaderClass"
              >
                <el-table-column align="center" type="index" width="30"></el-table-column>
                <el-table-column align="center" prop="studyDate" label="手术时间" width="80">
                  <template slot-scope="scope">
                    <span
                      v-if="scope.row.status == '待排程'"
                      @click="toSchedule(scope.row)"
                      class="status-noSchedule"
                    >
                      安排时间
                    </span>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="studyDate" label="拟手术时间" width="180">
                  <template slot-scope="scope">
                    <span>{{ dateFormat(scope.row.planStudyDate) }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="患者姓名" width="80" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-button @click="checkPatient(scope.row)" type="text" size="small">
                      {{ scope.row.patientsName }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsSex"
                  label="性别"
                  width="80"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  label="年龄"
                  width="80"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.ageDays">
                      {{ calculateAgeFromDays(scope.row.ageDays) }}
                    </span>
                    <span v-else>
                      {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="fileNumber"
                  label="库号"
                  width="120"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="hisNo"
                  label="住院号"
                  width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsID"
                  label="病案号"
                  width="150"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="ward"
                  label="病区"
                  width="120"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="bedNo"
                  label="床位号"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="importantItem"
                  label="重要事项"
                  width="200"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="clinicDiagnose"
                  label="术前诊断"
                  width="200"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="examineNameList"
                  label="手术名称"
                  min-width="250"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div class="examineNameListCenter">
                      <el-tag
                        v-for="(item, index) in scope.row.examineNameList"
                        :key="'examineName' + index"
                        style="margin-right: 5px"
                        type="info"
                        size="small"
                      >
                        {{ item }}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="examineDoctor"
                  label="手术医生"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="lab"
                  label="手术室"
                  width="100"
                ></el-table-column>

                <el-table-column align="center" label="登记日期" width="120">
                  <template slot-scope="scope" v-if="scope.row.studyApplyTime">
                    <span>{{ scope.row.studyApplyTime.substr(0, 10) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="status"
                  label="手术状态"
                  :formatter="get_status"
                  width="100"
                ></el-table-column>
              </el-table>
              <!-- 分页区域 -->
              <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="userInfo_notstart.pagenum"
                :page-sizes="[15, 30, 50]"
                :page-size="userInfo_wait.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="userInfo_wait.total"
              ></el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="未开始" name="2">
          <div class="surgery-show">
            <div class="surgery-show-content">
              <el-table
                :data="notStartTableData"
                border
                style="width: 100%"
                stripe
                :height="tableHeight"
                highlight-current-row
                :default-sort="{ prop: 'studyDate', order: 'descending' }"
                @sort-change="sortChange"
                :header-cell-class-name="handleHeaderClass"
              >
                <el-table-column align="center" type="index" width="30"></el-table-column>
                <el-table-column
                  align="center"
                  prop="studyDate"
                  label="手术时间"
                  width="180"
                ></el-table-column>

                <el-table-column align="center" label="患者姓名" width="80" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-button @click="checkPatient(scope.row)" type="text" size="small">
                      {{ scope.row.patientsName }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsSex"
                  label="性别"
                  width="80"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  label="年龄"
                  width="80"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.ageDays">
                      {{ calculateAgeFromDays(scope.row.ageDays) }}
                    </span>
                    <span v-else>
                      {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="fileNumber"
                  label="库号"
                  width="120"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="hisNo"
                  label="住院号"
                  width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsID"
                  label="病案号"
                  width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="ward"
                  label="病区"
                  width="120"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="bedNo"
                  label="床位号"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="importantItem"
                  label="重要事项"
                  width="200"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="clinicDiagnose"
                  label="术前诊断"
                  width="200"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="examineNameList"
                  label="手术名称"
                  min-width="250"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div class="examineNameListCenter">
                      <el-tag
                        v-for="(item, index) in scope.row.examineNameList"
                        :key="'examineName' + index"
                        style="margin-right: 5px"
                        type="info"
                        size="small"
                      >
                        {{ item }}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="examineDoctor"
                  label="手术医生"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="lab"
                  label="手术室"
                  width="100"
                ></el-table-column>

                <el-table-column align="center" label="登记日期" width="120">
                  <template slot-scope="scope" v-if="scope.row.studyApplyTime">
                    <span>{{ scope.row.studyApplyTime.substr(0, 10) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="status"
                  label="手术状态"
                  :formatter="get_status"
                  width="100"
                ></el-table-column>
              </el-table>
              <!-- 分页区域 -->
              <el-pagination
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :current-page="userInfo_notstart.pagenum"
                :page-sizes="[15, 30, 50]"
                :page-size="userInfo_notstart.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="userInfo_notstart.total"
              ></el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="进行中" name="3">
          <div class="surgery-show">
            <div class="surgery-show-content">
              <el-table
                :data="processingTableData"
                border
                style="width: 100%"
                :height="tableHeight"
                stripe
                highlight-current-row
                @sort-change="sortChange"
                :header-cell-class-name="handleHeaderClass"
              >
                <el-table-column align="center" type="index" width="30"></el-table-column>
                <el-table-column
                  align="center"
                  prop="studyDate"
                  label="手术时间"
                  width="180"
                ></el-table-column>

                <el-table-column align="center" label="患者姓名" width="80" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-button @click="checkPatient(scope.row)" type="text" size="small">
                      {{ scope.row.patientsName }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsSex"
                  label="性别"
                  width="80"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  label="年龄"
                  width="80"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.ageDays">
                      {{ calculateAgeFromDays(scope.row.ageDays) }}
                    </span>
                    <span v-else>
                      {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="fileNumber"
                  label="库号"
                  width="120"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="hisNo"
                  label="住院号"
                  width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsID"
                  label="病案号"
                  width="150"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="ward"
                  label="病区"
                  width="120"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="bedNo"
                  label="床位号"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="importantItem"
                  label="重要事项"
                  width="200"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="clinicDiagnose"
                  label="术前诊断"
                  width="200"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="examineNameList"
                  label="手术名称"
                  min-width="250"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div class="examineNameListCenter">
                      <el-tag
                        v-for="(item, index) in scope.row.examineNameList"
                        :key="'examineName' + index"
                        style="margin-right: 5px"
                        type="info"
                        size="small"
                      >
                        {{ item }}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="examineDoctor"
                  label="手术医生"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="lab"
                  label="手术室"
                  width="100"
                ></el-table-column>

                <el-table-column align="center" label="登记日期" width="120">
                  <template slot-scope="scope" v-if="scope.row.studyApplyTime">
                    <span>{{ scope.row.studyApplyTime.substr(0, 10) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="status"
                  label="手术状态"
                  :formatter="get_status"
                  width="100"
                ></el-table-column>
              </el-table>
              <!-- 分页区域 -->
              <el-pagination
                @size-change="handleSizeChange3"
                @current-change="handleCurrentChange3"
                :current-page="userInfo_processing.pagenum"
                :page-sizes="[15, 30, 50]"
                :page-size="userInfo_processing.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="userInfo_processing.total"
              ></el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已结束" name="4">
          <div class="surgery-show">
            <div class="surgery-show-content">
              <el-table
                :data="completedTableData"
                border
                style="width: 100%"
                :height="tableHeight"
                stripe
                highlight-current-row
                @sort-change="sortChange"
                :header-cell-class-name="handleHeaderClass"
              >
                <el-table-column align="center" type="index" width="30"></el-table-column>
                <el-table-column
                  align="center"
                  prop="studyDate"
                  label="手术时间"
                  width="180"
                ></el-table-column>

                <el-table-column align="center" label="患者姓名" width="80" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-button @click="checkPatient(scope.row)" type="text" size="small">
                      {{ scope.row.patientsName }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsSex"
                  label="性别"
                  width="80"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  label="年龄"
                  width="80"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.ageDays">
                      {{ calculateAgeFromDays(scope.row.ageDays) }}
                    </span>
                    <span v-else>
                      {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="fileNumber"
                  label="库号"
                  width="120"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="hisNo"
                  label="住院号"
                  width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsID"
                  label="病案号"
                  width="150"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="ward"
                  label="病区"
                  width="120"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="bedNo"
                  label="床位号"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="importantItem"
                  label="重要事项"
                  width="200"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="clinicDiagnose"
                  label="术前诊断"
                  width="200"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="examineNameList"
                  label="手术名称"
                  min-width="250"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div class="examineNameListCenter">
                      <el-tag
                        v-for="(item, index) in scope.row.examineNameList"
                        :key="'examineName' + index"
                        style="margin-right: 5px"
                        type="info"
                        size="small"
                      >
                        {{ item }}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="examineDoctor"
                  label="手术医生"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="lab"
                  label="手术室"
                  width="100"
                ></el-table-column>

                <el-table-column align="center" label="登记日期" width="120">
                  <template slot-scope="scope" v-if="scope.row.studyApplyTime">
                    <span>{{ scope.row.studyApplyTime.substr(0, 10) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="status"
                  label="手术状态"
                  :formatter="get_status"
                  width="100"
                ></el-table-column>
              </el-table>
              <!-- 分页区域 -->
              <el-pagination
                @size-change="handleSizeChange4"
                @current-change="handleCurrentChange4"
                :current-page="userInfo_completed.pagenum"
                :page-sizes="[15, 30, 50]"
                :page-size="userInfo_completed.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="userInfo_completed.total"
              ></el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已完成" name="5">
          <div class="surgery-show">
            <div class="surgery-show-content">
              <el-table
                :data="finishTableData"
                border
                style="width: 100%"
                :height="tableHeight"
                stripe
                highlight-current-row
                @sort-change="sortChange"
                :header-cell-class-name="handleHeaderClass"
              >
                <el-table-column align="center" type="index" width="30"></el-table-column>
                <el-table-column
                  align="center"
                  prop="studyDate"
                  label="手术时间"
                  width="180"
                ></el-table-column>

                <el-table-column align="center" label="患者姓名" width="80" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-button @click="checkPatient(scope.row)" type="text" size="small">
                      {{ scope.row.patientsName }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsSex"
                  label="性别"
                  width="80"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  label="年龄"
                  width="80"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.ageDays">
                      {{ calculateAgeFromDays(scope.row.ageDays) }}
                    </span>
                    <span v-else>
                      {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="fileNumber"
                  label="库号"
                  width="120"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="hisNo"
                  label="住院号"
                  width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsID"
                  label="病案号"
                  width="150"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="ward"
                  label="病区"
                  width="120"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="bedNo"
                  label="床位号"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="importantItem"
                  label="重要事项"
                  width="200"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="clinicDiagnose"
                  label="术前诊断"
                  width="200"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="examineNameList"
                  label="手术名称"
                  min-width="250"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div class="examineNameListCenter">
                      <el-tag
                        v-for="(item, index) in scope.row.examineNameList"
                        :key="'examineName' + index"
                        style="margin-right: 5px"
                        type="info"
                        size="small"
                      >
                        {{ item }}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="examineDoctor"
                  label="手术医生"
                  width="100"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="lab"
                  label="手术室"
                  width="100"
                ></el-table-column>

                <el-table-column align="center" label="登记日期" width="120">
                  <template slot-scope="scope" v-if="scope.row.studyApplyTime">
                    <span>{{ scope.row.studyApplyTime.substr(0, 10) }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  align="center"
                  prop="status"
                  label="手术状态"
                  :formatter="get_status"
                  width="100"
                ></el-table-column>
              </el-table>
              <!-- 分页区域 -->
              <el-pagination
                @size-change="handleSizeChange5"
                @current-change="handleCurrentChange5"
                :current-page="userInfo_finish.pagenum"
                :page-sizes="[15, 30, 50]"
                :page-size="userInfo_finish.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="userInfo_finish.total"
              ></el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="surgery-total">
        <span v-if="tabs_index === '1'">总计: {{ this.userInfo_wait.total }}条</span>
        <span v-if="tabs_index === '2'">总计: {{ this.userInfo_notstart.total }}条</span>
        <span v-if="tabs_index === '3'">总计: {{ this.userInfo_processing.total }}条</span>
        <span v-if="tabs_index === '4'">总计: {{ this.userInfo_completed.total }}条</span>
        <span v-if="tabs_index === '5'">总计: {{ this.userInfo_finish.total }}条</span>
        <span v-if="tabs_index === '0'">总计: {{ this.userInfo_all.total }}条</span>
      </div>
    </div>
  </div>
</template>

<script>
import IndexHeader from '../components/IndexHeader.vue'
import PatientInfoInputDialog from '../views/Login/components/PatientInfoInputDialog.vue'
import PatientInfoDialog from '../views/Login/components/patientInfoDialog.vue'
// import WaitStudyDialog from '../views/Login/components/WaitStudyDialog'
import SchedulingDialog from '../views/Login/components/SchedulingDialog'

import {
  getYesterDatDate,
  getTomorrowDate,
  getRecentWeekDate,
  getRecentMonthDate,
  dateFormat,
  todayDate
} from '../utils'
export default {
  components: {
    IndexHeader,
    PatientInfoInputDialog,
    PatientInfoDialog,
    // WaitStudyDialog,
    SchedulingDialog
  },
  data() {
    return {
      changeValue: '',
      CISReportInfo: {
        examineDoctor: null,
        patientsID: null,
        patientsName: null,
        operationName: null,
        deptName: null,
        studyEndDate: null,
        studyStartDate: null
      },
      time_dialogVisible: false,
      surgeryNameLoading: false,
      tableData: [],
      notStartTableData: [],
      processingTableData: [],
      completedTableData: [],
      finishTableData: [],
      waitTableData: [],
      userInfo_notstart: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      userInfo_processing: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      userInfo_completed: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      userInfo_finish: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      userInfo_wait: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      userInfo_all: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      options: [
        {
          value: '选项1',
          label: '今天',
          type: 0
        },
        {
          value: '选项2',
          label: '昨天',
          type: 0
        },
        {
          value: '选项3',
          label: '明天',
          type: 0
        },
        {
          value: '选项4',
          label: '最近一周',
          type: 0
        },
        {
          value: '选项5',
          label: '最近一月',
          type: 0
        },
        {
          label: '自定义',
          value: '选项6',
          type: 0,
          children: [
            {
              label: '',
              type: 1,
              value: ''
            }
          ]
        }
      ],
      surgeryDate: '选项1',
      tableHeight: window.innerHeight - 335, //表格动态高度
      screenHeight: window.innerHeight, //内容区域高度
      tabs_index: '0',
      pubOperationList: [],
      sickAreaList: [],
      doctorList: [],
      queryGet: '',
      syncBtnIsShow: false,
      detailsBtnIsShow: false,
      simpleBtnIsShow: false,
      isToDetailPage: '',
      ordersList: []
    }
  },
  created() {
    this.get_localtime()
    this.getSyncBtnIsShow()
    this.getDoctorList()
  },
  watch: {
    // 监听screenHeight从而改变table的高度
    screenHeight(val) {
      this.screenHeight = val
      this.tableHeight = this.screenHeight - 335
    }
  },
  methods: {
    // 排序
    sortChange({ column, prop, order }) {
      if (column.sortable !== 'custom') {
        return
      }
      column.multiOrder = order
      this.handleOrderChange(column.property, column.multiOrder)
    },
    handleOrderChange(orderColumn, orderState) {
      if (orderState) {
        let flagIsHave = false
        this.ordersList.forEach((element) => {
          if (element.orderColumn === orderColumn) {
            element.orderState = orderState
            flagIsHave = true
          }
        })
        if (!flagIsHave) {
          this.ordersList.push({
            orderColumn: orderColumn,
            orderState: orderState
          })
        }
      } else {
        this.ordersList = this.ordersList.filter((element) => {
          return element.orderColumn !== orderColumn
        })
      }
      if (this.ordersList.length) {
        this.querySearch()
      }
    },
    handleHeaderClass({ column }) {
      this.ordersList.forEach((element) => {
        if (column.property === element.orderColumn) {
          column.order = element.orderState
        }
      })
    },

    calculateAgeFromDays(days) {
      if (days) {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()
        const birthDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000)
        const birthYear = birthDate.getFullYear()
        let age = currentYear - birthYear

        // 检查生日是否已经过了，如果还没过，则减去一年
        if (currentDate < new Date(birthDate.setFullYear(currentYear))) {
          age--
        }

        if (age < 1) {
          return days + '天'
        } else if (age < 2) {
          const remainingDays = Math.floor((currentDate - birthDate) / (24 * 60 * 60 * 1000))
          return '1年零' + remainingDays + '天'
        } else {
          return age + '岁'
        }
      } else {
        return null
      }
    },

    getDoctorList() {
      this.$api.get(`/v1/webconsole/study/doctors`).then((res) => {
        let arr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            arr.push({
              userName: item.userName,
              userId: item.userID
            })
          })
          this.doctorList = arr
        }
      })
    },
    // 是否显示手术同步按钮
    getSyncBtnIsShow() {
      let data = {
        code: 'OPERATION_SYNC'
      }
      this.$api.get(`/v1/webconsole/sys/defaultValue`, data).then((res) => {
        if (res.data && res.data.data) {
          if (res.data.data === '是') {
            this.syncBtnIsShow = true
          } else {
            this.syncBtnIsShow = false
          }
        }
      })
      let detailsData = {
        code: 'DETAILS_REGISTRATION'
      }
      this.$api.get(`/v1/webconsole/sys/defaultValue`, detailsData).then((res) => {
        if (res.data && res.data.data) {
          if (res.data.data === '是') {
            this.detailsBtnIsShow = true
          } else {
            this.detailsBtnIsShow = false
          }
        }
      })
      let simpleData = {
        code: 'SIMPLE_REGISTRATION'
      }
      this.$api.get(`/v1/webconsole/sys/defaultValue`, simpleData).then((res) => {
        if (res.data && res.data.data) {
          if (res.data.data === '是') {
            this.simpleBtnIsShow = true
          } else {
            this.simpleBtnIsShow = false
          }
        }
      })
      let isToDetailPageData = {
        code: 'REGISTER_AFTER_TO_DETAIL_PAGE'
      }
      this.$api.get(`/v1/webconsole/sys/defaultValue`, isToDetailPageData).then((res) => {
        if (res.data && res.data.data) {
          if (res.data.data === '否') {
            this.isToDetailPage = '否'
          }
        }
      })
    },
    getSickAreaListList() {
      this.$api.get(`/v1/webconsole/user/depts`).then((res) => {
        let arr = []
        if (res.data && res.data.data.length > 0) {
          res.data.data.map((item) => {
            arr.push({
              name: item.name,
              id: item.id
            })
          })
          this.sickAreaList = arr
        }
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.surgeryNameLoading = true
        setTimeout(() => {
          this.surgeryNameLoading = false
          this.getPubOperation(query)
        }, 200)
      } else {
        this.pubOperationList = []
      }
    },
    getPubOperation(query) {
      if (query) {
        this.queryGet = query
      }
      this.$api
        .get(`/v1/webconsole/study/puboperationlist?keyword=${this.queryGet}`)
        .then((res) => {
          let arr = []
          if (res.data.data.length > 0) {
            res.data.data.map((item) => {
              arr.push({ label: item.dictName, value: item.dictName })
            })
            this.pubOperationList = arr
          }
        })
    },
    changeSearchSurgeryName(val) {
      if (val != undefined) {
        // 判断值和变量,如果之和变量相等,则不做操作,否则执行
        if (this.changeValue == val) {
          return
        } else {
          // 储存我所改变的值
          this.changeValue = val
          this.querySearch(1)
        }
      }
    },
    changeSearch(val) {
      if (val != undefined) {
        // 判断值和变量,如果之和变量相等,则不做操作,否则执行
        if (this.changeValue == val) {
          return
        } else {
          // 储存我所改变的值
          this.changeValue = val
          this.querySearch(1)
        }
      }
    },

    // 处理时间
    getYesterDatDate,
    getTomorrowDate,
    getRecentWeekDate,
    getRecentMonthDate,
    dateFormat,
    todayDate,
    //手术信息同步
    confirm_information() {
      this.axios
        .post('/v1/webconsole/sync/CISReport')
        .then((res) => {
          if (res.data.status == 200) {
            this.querySearch()
            loading.close()
            this.$notify({
              title: '成功',
              message: res.data.msg,
              type: 'success'
            })
          } else {
            loading.close()
            this.$notify({
              title: '失败',
              message: res.data.msg,
              type: 'warning'
            })
          }
        })
        .catch(() => {
          loading.close()
          this.$notify({
            title: '失败',
            message: '请求超时',
            type: 'warning'
          })
        })
      const loading = this.$loading({
        lock: true,
        text: '正在同步中...请勿关闭页面',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    get_localtime() {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1
      const day = today.getDate()
      this.CISReportInfo.studyStartDate = year + '-' + month + '-' + day + ' ' + '00' + ':' + '00'
      this.CISReportInfo.studyEndDate = year + '-' + month + '-' + day + ' ' + '23' + ':' + '59'
    },
    // 选择日期
    dateManuPick(event) {
      const today = new Date()
      const startDate = '00' + ':' + '00'
      const endDate = '23' + ':' + '59'
      const todayDate = this.todayDate(today)
      // 今天
      if (event === '选项1') {
        // 获取今天
        this.CISReportInfo.studyStartDate = todayDate + ' ' + startDate
        this.CISReportInfo.studyEndDate = todayDate + ' ' + endDate
      } else if (event === '选项2') {
        //获取到昨天
        const date = this.getYesterDatDate(today)
        this.CISReportInfo.studyStartDate = date + ' ' + startDate
        this.CISReportInfo.studyEndDate = date + ' ' + endDate
      } else if (event === '选项3') {
        //获取到明天
        const date = this.getTomorrowDate(today)
        this.CISReportInfo.studyStartDate = date + ' ' + startDate
        this.CISReportInfo.studyEndDate = date + ' ' + endDate
      } else if (event === '选项4') {
        //获取到最近一周
        const weekDate = this.getRecentWeekDate(today)
        this.CISReportInfo.studyStartDate = weekDate + ' ' + startDate
        this.CISReportInfo.studyEndDate = todayDate + ' ' + endDate
      } else if (event === '选项5') {
        // 最近一月
        const date = this.getRecentMonthDate(today)
        this.CISReportInfo.studyStartDate = date + ' ' + startDate
        this.CISReportInfo.studyEndDate = todayDate + ' ' + endDate
      } else if (event === '选项6') {
        this.time_dialogVisible = !this.time_dialogVisible
        this.CISReportInfo.studyStartDate = ''
      }
      // 清空
      if (!event) {
        this.CISReportInfo.studyStartDate = ''
        this.CISReportInfo.studyEndDate = ''
      }
      let searchOption = {
        patientID: this.CISReportInfo.patientsID,
        patientName: this.CISReportInfo.patientsName,
        examineDoctor: this.CISReportInfo.examineDoctor,
        operationName: this.CISReportInfo.operationName,
        startTime: this.CISReportInfo.studyStartDate,
        endTime: this.CISReportInfo.studyEndDate,
        size: this.userInfo_all.pagesize,
        current: this.userInfo_all.pagenum,
        status: null
      }
      for (let i in searchOption) {
        if (searchOption[i] === '') {
          searchOption[i] = null
        }
      }
      if (event != '选项6') {
        this.querySearch(1)
      }
    },
    // 清空
    clearTable() {
      for (let i in this.CISReportInfo) {
        this.CISReportInfo[i] = null
      }
      this.surgeryDate = ''
      this.tabs_index = '0'
      this.querySearch(1)
      this.getPubOperation()
    },
    search() {
      this.querySearch(1)
    },
    // 统计报表
    report() {
      const { href } = this.$router.resolve({
        path: `/Report`
      })
      window.open(href, '_blank')
    },
    dialogInfo() {
      this.$refs.patientInfoDialog.Show()
    },
    dialogDetailsInfo() {
      this.$refs.patientInfoInputDialog.Show()
    },
    dialogSave() {
      this.querySearch()
    },
    // 详情页
    checkPatient(row) {
      localStorage.setItem('patientInfo', JSON.stringify(row))
      const { href } = this.$router.resolve({
        path: `/Detail/BaseInfo?operationId=${row.uuid}`
      })
      window.open(href, '_blank')
    },
    // 排程弹窗
    toSchedule(row) {
      this.$refs.SchedulingDialog.Show(row)
      // this.$refs.waitStudyDialog.Show(row)
    },
    // 查询全部病人
    querySearch(page) {
      var info = ''
      var a = '',
        b = ''
      if (this.tabs_index === '1') {
        info = '待排程'
        if (page) {
          this.userInfo_wait.pagenum = page
        }
        a = this.userInfo_wait.pagenum
        b = this.userInfo_wait.pagesize
      } else if (this.tabs_index === '2') {
        info = '未开始'
        if (page) {
          this.userInfo_notstart.pagenum = page
        }
        a = this.userInfo_notstart.pagenum
        b = this.userInfo_notstart.pagesize
      } else if (this.tabs_index === '4') {
        info = '已结束'
        if (page) {
          this.userInfo_completed.pagenum = page
        }
        a = this.userInfo_completed.pagenum
        b = this.userInfo_completed.pagesize
      } else if (this.tabs_index === '5') {
        info = '已完成'
        if (page) {
          this.userInfo_finish.pagenum = page
        }
        a = this.userInfo_finish.pagenum
        b = this.userInfo_finish.pagesize
      } else if (this.tabs_index === '0') {
        info = null
        if (page) {
          this.userInfo_all.pagenum = page
        }
        a = this.userInfo_all.pagenum
        b = this.userInfo_all.pagesize
      } else {
        info = '进行中'
        if (page) {
          this.userInfo_processing.pagenum = page
        }
        b = this.userInfo_processing.pagesize
        a = this.userInfo_processing.pagenum
      }

      let searchOption = {
        patientID: this.CISReportInfo.patientsID,
        patientName: this.CISReportInfo.patientsName,
        examineDoctor: this.CISReportInfo.examineDoctor,
        operationName: this.CISReportInfo.operationName,
        deptName: this.CISReportInfo.deptName,
        startTime: this.CISReportInfo.studyStartDate,
        endTime: this.CISReportInfo.studyEndDate,
        size: b,
        current: a,
        status: info,
        ordersList: ''
      }

      if (this.ordersList?.length) {
        this.ordersList.forEach((item) => {
          // if (item.orderColumn === 'patientsName') {
          //   if (item.orderState === 'descending') {
          //     data.orderString = data.orderString
          //       ? data.orderString + ',' + 'PubPatients.PATIENTSNAME desc'
          //       : 'PubPatients.PATIENTSNAME desc'
          //   } else {
          //     data.orderString = data.orderString
          //       ? data.orderString + ',' + 'PubPatients.PATIENTSNAME'
          //       : 'PubPatients.PATIENTSNAME'
          //   }
          // }
          if (item.orderColumn === 'patientsSex') {
            if (item.orderState === 'descending') {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'PubPatients.PATIENTSSEX desc'
                : 'PubPatients.PATIENTSSEX desc'
            } else {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'PubPatients.PATIENTSSEX'
                : 'PubPatients.PATIENTSSEX'
            }
          }
          if (item.orderColumn === 'patientsAge') {
            if (item.orderState === 'descending') {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'OPERATION.PATIENTSAGE desc'
                : 'OPERATION.PATIENTSAGE desc'
            } else {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'OPERATION.PATIENTSAGE'
                : 'OPERATION.PATIENTSAGE'
            }
          }
          if (item.orderColumn === 'clinicDiagnose') {
            if (item.orderState === 'descending') {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'OPERATION.CLINICDIAGNOSE desc'
                : 'OPERATION.CLINICDIAGNOSE desc'
            } else {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'OPERATION.CLINICDIAGNOSE'
                : 'OPERATION.CLINICDIAGNOSE'
            }
          }
          if (item.orderColumn === 'examineNameList') {
            if (item.orderState === 'descending') {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'OPERATION.EXAMINENAMES desc'
                : 'OPERATION.EXAMINENAMES desc'
            } else {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'OPERATION.EXAMINENAMES'
                : 'OPERATION.EXAMINENAMES'
            }
          }
          if (item.orderColumn === 'examineDoctor') {
            if (item.orderState === 'descending') {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'OPERATION.EXAMINEDOCTOR desc'
                : 'OPERATION.EXAMINEDOCTOR desc'
            } else {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'OPERATION.EXAMINEDOCTOR'
                : 'OPERATION.EXAMINEDOCTOR'
            }
          }
          if (item.orderColumn === 'studyApplyTime') {
            if (item.orderState === 'descending') {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'OPERATION.STUDYAPPLYTIME desc'
                : 'OPERATION.STUDYAPPLYTIME desc'
            } else {
              searchOption.orderString = searchOption.orderString
                ? searchOption.orderString + ',' + 'OPERATION.STUDYAPPLYTIME'
                : 'OPERATION.STUDYAPPLYTIME'
            }
          }
        })
      }

      for (let i in searchOption) {
        if (searchOption[i] === '') {
          searchOption[i] = null
        }
      }

      this.$api.get('/v1/webconsole/study/query', searchOption).then(
        (res) => {
          if (info === '未开始') {
            this.notStartTableData = res.data.data.records
            this.userInfo_notstart.total = res.data.data.total
          } else if (info === '进行中') {
            this.processingTableData = res.data.data.records
            this.userInfo_processing.total = res.data.data.total
          } else if (info === '待排程') {
            this.waitTableData = res.data.data.records
            this.userInfo_wait.total = res.data.data.total
          } else if (info === '已结束') {
            this.completedTableData = res.data.data.records
            this.userInfo_completed.total = res.data.data.total
          } else if (info === '已完成') {
            this.finishTableData = res.data.data.records
            this.userInfo_finish.total = res.data.data.total
          } else {
            this.tableData = res.data.data.records
            this.userInfo_all.total = res.data.data.total
          }
        },
        () => {
          this.$message.error('查询失败')
        }
      )
    },
    //获得报告状态
    get_status(val) {
      return val.status
    },
    // 监听 pagesize 改变的事件
    handleSizeChange1(newSize) {
      this.userInfo_wait.pagesize = newSize
      this.querySearch()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange1(newPage) {
      this.userInfo_wait.pagenum = newPage
      this.querySearch()
    },
    // 监听 pagesize 改变的事件
    handleSizeChange2(newSize) {
      this.userInfo_notstart.pagesize = newSize
      this.querySearch()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange2(newPage) {
      this.userInfo_notstart.pagenum = newPage
      this.querySearch()
    },
    // 监听 pagesize 改变的事件
    handleSizeChange3(newSize) {
      this.userInfo_processing.pagesize = newSize
      this.querySearch()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange3(newPage) {
      this.userInfo_processing.pagenum = newPage
      this.querySearch()
    },
    // 监听 pagesize 改变的事件
    handleSizeChange4(newSize) {
      this.userInfo_completed.pagesize = newSize
      this.querySearch()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange4(newPage) {
      this.userInfo_completed.pagenum = newPage
      this.querySearch()
    },
    // 监听 pagesize 改变的事件
    handleSizeChange5(newSize) {
      this.userInfo_finish.pagesize = newSize
      this.querySearch()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange5(newPage) {
      this.userInfo_finish.pagenum = newPage
      this.querySearch()
    },
    // 监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.userInfo_all.pagesize = newSize
      this.querySearch()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.userInfo_all.pagenum = newPage
      this.querySearch()
    },
    handleClick(tab) {
      this.tabs_index = tab.index
      this.querySearch()
    },
    cancel_time() {
      this.CISReportInfo.studyStartDate = ''
      this.CISReportInfo.studyEndDate = ''
      this.surgeryDate = ''
      this.time_dialogVisible = !this.time_dialogVisible
    },
    confirm_time() {
      this.querySearch(1)
      this.surgeryDate =
        this.CISReportInfo.studyStartDate?.substring(0, 11) +
        this.CISReportInfo.studyEndDate?.substring(0, 11)
      this.time_dialogVisible = !this.time_dialogVisible
    }
  },
  mounted() {
    this.querySearch()
    window.onresize = () => {
      return () => {
        // window.innerHeight:浏览器的可用高度
        window.screenHeight = window.innerHeight
        this.screenHeight = window.screenHeight
      }
    }
    this.getPubOperation()
    this.getSickAreaListList()
  }
}
</script>
