<template>
  <el-header>
    <div class="header-left">
      <div class="logo">
        <img src="../assets/img/logo.png" alt="" />
        <div class="header_title_style">{{ system_title }}</div>
      </div>
      <div class="header_button_style" v-for="(item, index) in userModuleList" :key="index">
        <el-button
          v-if="item.modulecode === 'Solution'"
          type="text"
          :class="is_7 ? 'button_style1' : 'button_style'"
          @click="solution_sys"
        >
          {{ item.modulename }}
        </el-button>
        <el-button
          v-if="item.modulecode === 'OperationManage'"
          type="text"
          :class="is_1 ? 'button_style1' : 'button_style'"
          @click="operation_sys"
        >
          {{ item.modulename }}
        </el-button>
        <el-button
          v-if="item.modulecode === 'OperationScheduling'"
          @click="goto_labForm"
          type="text"
          :class="is_5 ? 'button_style1' : 'button_style'"
        >
          {{ item.modulename }}
        </el-button>
        <el-button
          v-if="item.modulecode === 'ResearchQueue'"
          @click="goto_researchQueue"
          type="text"
          :class="is_6 ? 'button_style1' : 'button_style'"
        >
          {{ item.modulename }}
        </el-button>
        <el-button
          v-if="item.modulecode === 'SpecializedDisease'"
          @click="search_summary"
          type="text"
          :class="is_2 ? 'button_style1' : 'button_style'"
        >
          {{ item.modulename }}
        </el-button>
        <el-button
          v-if="item.modulecode === 'FollowManage'"
          @click="goto_visitForm"
          type="text"
          :class="is_4 ? 'button_style1' : 'button_style'"
        >
          {{ item.modulename }}
        </el-button>
        <el-button
          v-if="item.modulecode === 'StatisticAnalysis'"
          @click="goto_statistics"
          type="text"
          :class="is_9 ? 'button_style1' : 'button_style'"
        >
          {{ item.modulename }}
        </el-button>
        <el-button
          v-if="item.modulecode === 'QualityControl'"
          @click="goto_qualityControl"
          type="text"
          :class="is_8 ? 'button_style1' : 'button_style'"
        >
          {{ item.modulename }}
        </el-button>
        <el-button
          v-if="item.modulecode === 'SystemManage'"
          @click="goto_report_manage"
          type="text"
          :class="is_3 ? 'button_style1' : 'button_style'"
        >
          {{ item.modulename }}
        </el-button>
      </div>
    </div>
    <div class="header-right">
      <div class="search-label">
        <el-button round size="mini" style="margin-right: 5px" @click="goto_search_home">
          <i class="el-icon-search"></i>
          高级搜索
        </el-button>
      </div>
      <div class="server-time">
        <div>
          <span>{{ local_time | formateTime }}</span>
        </div>
      </div>
      <div class="user">
        <el-dropdown @command="handleCommand" trigger="click">
          <div class="user-avator">
            <img src="../assets/img/doctor.png" />
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu slot="dropdown" divided>
            <div style="padding: 0 1.25rem; margin-bottom: 5px">
              <i class="el-icon-user" style="margin-right: 5px"></i>
              {{ this.user_name }}
            </div>
            <el-dropdown-item command="editSignPassword" icon="el-icon-edit">
              修改签名密码
            </el-dropdown-item>
            <el-dropdown-item command="editPassword" icon="el-icon-s-promotion">
              修改密码
            </el-dropdown-item>
            <el-dropdown-item command="user" icon="el-icon-user-solid" divided>
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 修改签名/密码弹窗 -->
    <el-dialog
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      v-dialogDrag
      :title="editPwdType == 'sign' ? '用户签名密码修改' : '用户密码修改'"
      :visible.sync="edit_visible"
      width="30%"
      :show-close="false"
    >
      <el-form :model="editpwd_form" :rules="rules" ref="editpwd_form">
        <el-form-item label="用户名:">
          <el-input v-model="this.user_name" :disabled="true" style="width: 30%"></el-input>
        </el-form-item>
        <el-form-item label="原密码:" prop="oldpwd" required>
          <el-input v-model="editpwd_form.oldpwd" style="width: 30%" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="newpwd" required>
          <el-input v-model="editpwd_form.newpwd" style="width: 30%" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="confimpwd" required>
          <el-input v-model="editpwd_form.confimpwd" style="width: 30%" type="password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel_editpwd('editpwd_form')" size="mini">取 消</el-button>
        <el-button type="primary" @click="determine_pwd('editpwd_form')" size="mini">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </el-header>
</template>

<script>
var parseInitDate = function (datetime) {
  return datetime < 10 ? '0' + datetime : datetime
}
import { clearCookie } from '../assets/js/cookieUtils'
import { ADMIN_SERVER_HOST } from '../utils/globalVariable'
export default {
  name: 'IndexHeader',
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.editpwd_form.newpwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      system_title: '',
      local_time: new Date(),
      user_name: '',
      is_1: false,
      is_2: false,
      is_3: false,
      is_4: false,
      is_5: false,
      is_6: false,
      is_7: false,
      is_8: false,
      is_9: false,
      edit_visible: false,
      editPwdType: '',
      editpwd_form: {
        oldpwd: '',
        newpwd: '',
        confimpwd: ''
      },
      //修改密码校验规则
      rules: {
        oldpwd: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newpwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            min: 6,
            max: 12,
            message: '密码长度为6-12个字符，且必须同时包含数字和字母',
            trigger: 'blur'
          }
        ],
        confimpwd: [{ validator: validatePass2, trigger: 'blur' }]
      },
      userModuleList: []
    }
  },
  created() {
    this.getUserModule()
    this.getUrl()
    this.system_title = localStorage.getItem('system_title')
    this.user_name = localStorage.getItem('name')
    let that = this
    this.user_id = localStorage.getItem('userCode')
    this.timer = setInterval(function () {
      that.local_time = new Date()
    }, 1000)
  },
  filters: {
    formateTime: function (val) {
      var date = new Date(val)
      var year = date.getFullYear()
      var month = parseInitDate(date.getMonth() + 1)
      var day = parseInitDate(date.getDate())
      var hours = parseInitDate(date.getHours())
      var min = parseInitDate(date.getMinutes())
      var sec = parseInitDate(date.getSeconds())
      return year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec + ' '
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    getUserModule() {
      this.$api.get('/v1/webconsole/user/modules').then((res) => {
        if (res.data && res.data.data) {
          this.userModuleList = res.data.data
        }
      })
    },
    getUrl() {
      if (this.$route.path == '/Home') {
        this.is_1 = true
        this.is_2 = false
        this.is_4 = false
        this.is_3 = false
        this.is_5 = false
        this.is_6 = false
        this.is_7 = false
        this.is_8 = false
        this.is_9 = false
      }
      if (this.$route.path == '/Database') {
        this.is_2 = true
        this.is_1 = false
        this.is_3 = false
        this.is_4 = false
        this.is_5 = false
        this.is_6 = false
        this.is_7 = false
        this.is_8 = false
        this.is_9 = false
      }
      if (this.$route.path == '/Database/StatisticalInfo') {
        this.is_2 = true
        this.is_1 = false
        this.is_3 = false
        this.is_4 = false
        this.is_5 = false
        this.is_6 = false
        this.is_7 = false
        this.is_8 = false
        this.is_9 = false
      }
      if (this.$route.path == '/FollowHome') {
        this.is_4 = true
        this.is_1 = false
        this.is_2 = false
        this.is_3 = false
        this.is_5 = false
        this.is_6 = false
        this.is_7 = false
        this.is_8 = false
        this.is_9 = false
      }
      if (this.$route.path == '/FollowNav') {
        this.is_4 = true
        this.is_1 = false
        this.is_2 = false
        this.is_3 = false
        this.is_5 = false
        this.is_6 = false
        this.is_7 = false
        this.is_8 = false
        this.is_9 = false
      }
      if (this.$route.path == '/OperationRoom') {
        this.is_5 = true
        this.is_1 = false
        this.is_2 = false
        this.is_3 = false
        this.is_4 = false
        this.is_6 = false
        this.is_7 = false
        this.is_8 = false
        this.is_9 = false
      }
      if (this.$route.path == '/ResearchQueue') {
        this.is_6 = true
        this.is_1 = false
        this.is_2 = false
        this.is_3 = false
        this.is_4 = false
        this.is_5 = false
        this.is_7 = false
        this.is_8 = false
        this.is_9 = false
      }
      if (this.$route.path == '/solution') {
        this.is_7 = true
        this.is_1 = false
        this.is_2 = false
        this.is_3 = false
        this.is_4 = false
        this.is_5 = false
        this.is_6 = false
        this.is_8 = false
        this.is_9 = false
      }
      if (this.$route.path == '/QualityReport') {
        this.is_8 = true
        this.is_1 = false
        this.is_2 = false
        this.is_3 = false
        this.is_4 = false
        this.is_5 = false
        this.is_6 = false
        this.is_7 = false
        this.is_9 = false
      }
      if (this.$route.path == '/Voyager') {
        this.is_9 = true
        this.is_1 = false
        this.is_2 = false
        this.is_3 = false
        this.is_4 = false
        this.is_5 = false
        this.is_6 = false
        this.is_7 = false
        this.is_8 = false
      }
    },
    goto_search_home() {
      this.$router.push('/AdvancedSearch')
    },
    goto_qualityControl() {
      this.is_7 = !this.is_7
      this.$router.push('/QualityReport')
    },
    //治疗方案
    solution_sys() {
      this.is_7 = !this.is_7
      this.$router.push('/solution')
    },
    // 手术管理
    operation_sys() {
      this.is_1 = !this.is_1
      this.$router.push('/Home')
    },
    // 随访管理
    goto_visitForm() {
      this.is_4 = !this.is_4
      this.$router.push('/FollowNav')
    },
    // 手术排程
    goto_labForm() {
      this.is_5 = !this.is_5
      this.$router.push('/OperationRoom')
    },
    // 患者中心
    goto_researchQueue() {
      this.is_6 = !this.is_6
      this.$router.push('/ResearchQueue')
    },
    //去往后台系统管理
    goto_report_manage() {
      this.$api.get(`/v1/webconsole/sys/defaultValue?code=SYS_ADMIN_URL`).then((res) => {
        if (res.data.status === 200) {
          window.open(res.data?.data || `${ADMIN_SERVER_HOST}`)
        } else {
          this.$message.error(res.data?.msg || '网络错误！')
        }
      })
    },
    //专病数据库 QueryStatistics
    search_summary() {
      this.is_2 = !this.is_2
      this.$router.push('/Database')
    },
    goto_statistics() {
      this.is_9 = !this.is_9
      this.$router.push('/Voyager')
    },
    handleCommand(command) {
      if (command === 'editSignPassword') {
        this.editPwdType = 'sign'
        this.edit_visible = true
      } else if (command === 'editPassword') {
        this.editPwdType = 'login'
        this.edit_visible = true
      } else {
        this.axios.post('/v1/webconsole/user/logout')
        sessionStorage.clear()
        localStorage.clear()
        clearCookie()
        this.$router.replace('/')
      }
    },
    //取消密码修改
    cancel_editpwd(formName) {
      this.edit_visible = false
      this.$refs[formName].resetFields()
    },
    //确定密码修改
    determine_pwd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 判断是否是签名
          let url
          if (this.editPwdType == 'sign') {
            url = `/v1/webconsole/user/change/signpassword?oldPwd=${this.editpwd_form.oldpwd}&newPwd=${this.editpwd_form.newpwd}`
          } else {
            url = `/v1/webconsole/user/change/password?oldPwd=${this.editpwd_form.oldpwd}&newPwd=${this.editpwd_form.newpwd}`
          }
          this.axios.put(url).then((res) => {
            if (res.data.status == 200) {
              this.msg = this.$message({
                duration: 1000,
                type: 'success',
                message: '修改成功'
              })
              this.edit_visible = false
              if (this.editPwdType == 'login') {
                setTimeout(() => {
                  this.$confirm('请重新登录', '提示', {
                    confirmButtonText: '确定',
                    showClose: false,
                    showCancelButton: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    type: 'error'
                  }).then(() => {
                    this.axios.post('/v1/webconsole/user/logout'),
                      sessionStorage.clear(),
                      localStorage.clear(),
                      clearCookie(),
                      this.$router.replace('/')
                  })
                }, 500)
              }
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
@media screen and (max-width: 1260px) {
  .el-header {
    height: 88px !important;
  }
}
.el-header {
  background: #2d507e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  /deep/ .el-form-item__label {
    width: 20%;
  }
  .header-left {
    display: flex;
    align-items: center;
    width: 80%;
    height: 100%;
    .logo {
      width: 400px;
      white-space: nowrap;
      color: #fff;
      display: flex;
      align-items: center;
      padding-bottom: 2.5px;
      margin-right: 10px;
      img {
        height: 33.6px;
        width: 40px;
        margin-right: 10px;
      }
      .header_title_style {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .header_button_style {
      display: flex;
      align-items: center;
    }
    .header_button_style .el-button:hover {
      color: #fff;
    }
    .header_button_style .el-button:focus {
      color: #fff;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    // .search-label {
    //   margin-right: 10px;
    // }
    .server-time {
      display: flex;
      align-items: center;
      margin-right: 10px;
      height: 100%;
      text-align: right;
      span {
        font-size: 18px;
        margin-right: 10px;
      }
    }
    .userName {
      width: 80px;
      font-size: 18px;
      margin-right: 10px;
    }
    .user {
      text-align: justify;
      width: 60px;
      display: flex;
      align-items: center;
      .el-avatar {
        margin-right: 10px;
      }
      i {
        color: #fff;
      }
      .user-avator {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .user-avator img {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }
}
.button_style {
  color: #ccc;
  margin-right: 20px;
  font-weight: bold;
  font-size: 18px;
}
.button_style1 {
  color: #fff;
  margin-right: 20px;
  font-weight: bold;
  font-size: 18px;
}
</style>
