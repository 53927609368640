<style lang="less" scoped>
.crt-content {
  z-index: 999;
  position: fixed;
}
.new-css {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}
.input-form-item-content {
  width: 180px;
}
.el-divider__text.is-left {
  font-size: 18px;
}
.el-descriptions {
  margin: 10px 25px;
}
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
  .item-row {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-left: 27px;
    input#regDate {
      padding: 0 20px;
    }
    input#studyDate {
      padding: 0 20px;
    }
    .el-date-editor .el-range__icon {
      line-height: 19px;
    }
    .el-date-editor .el-range-separator {
      line-height: 19px;
    }
    .el-date-editor .el-range__close-icon {
      line-height: 19px;
    }
    .el-icon-time:before {
      content: '' !important;
    }
    .el-icon-date:before {
      content: '' !important;
    }
    .el-icon-arrow-up:before {
      content: '' !important;
    }

    span {
      margin: 0 15px;
    }
    .item-content {
      width: 180px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      .el-date-editor.el-input {
        width: 180px;
      }
      .el-select {
        /deep/.el-input--suffix {
          display: inline-block;
        }
        /deep/.el-input--suffix .el-input__inner {
          height: 30px !important;
        }
      }
    }
  }
}

.input_has_unit {
  margin-left: 0px !important;
}
</style>

<template>
  <CmDialogBorder size="big" :zIndex="zIndex" title="手术申请单" v-show="isShow" @close="Close">
    <div class="crt-content new-css">
      <h3 style="margin-left: 30px">
        <span>申请科室：{{ department }}</span>
        <!-- <span style="margin-left: 120px">申请时间：{{ patientInfoInput.studyApplyTime }}</span> -->
      </h3>
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="open_save"
      >
        保存
      </el-button>
      <!-- <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button> -->
    </div>
    <div class="header" style="padding: 24px"></div>
    <el-divider content-position="left">病人信息</el-divider>
    <div class="top-item-row">
      <div class="item-row">
        <span>
          患者姓名
          <span style="margin: 0; color: red">*</span>
        </span>
        <div>
          <el-input
            id="patientsName"
            class="input-form-item-content"
            placeholder="请输入"
            type="text"
            v-model="patientInfoInput.patientsName"
            size="mini"
          ></el-input>
        </div>
      </div>
      <div class="item-row">
        <span>
          性别
          <span style="margin: 0; color: red">*</span>
        </span>
        <cm-select
          style="margin: 5px 0 5px 0"
          class="input-form-item-content"
          v-model="patientInfoInput.patientsSex"
          activeUrl="/v1/webconsole/comboData/get/ssdj_patientsSex"
        ></cm-select>
      </div>
      <div class="item-row">
        <span>
          病案号
          <span style="margin: 0; color: red">*</span>
        </span>

        <div>
          <el-input
            class="input-form-item-content"
            placeholder="请输入"
            type="text"
            v-model="patientInfoInput.patientsID"
            size="mini"
          ></el-input>
        </div>
      </div>
      <div class="item-row">
        <span>病人性质</span>
        <cm-select
          class="input-form-item-content"
          v-model="patientInfoInput.insuranceType"
          activeUrl="/v1/webconsole/comboData/get/PUB_insuranceType"
        ></cm-select>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>年龄</span>
        <div>
          <el-input
            class="input-form-item-content"
            placeholder="请输入"
            type="text"
            v-model="patientInfoInput.patientsAge"
            size="mini"
          ></el-input>
        </div>
      </div>
      <div class="item-row">
        <span>身高</span>
        <div>
          <el-input
            class="input-form-item-content"
            placeholder="请输入"
            type="text"
            v-model="patientInfoInput.height"
            size="mini"
          >
            <template slot="append">cm</template>
          </el-input>
        </div>
      </div>
      <div class="item-row">
        <span>体重</span>
        <div>
          <el-input
            class="input-form-item-content"
            placeholder="请输入"
            type="text"
            v-model="patientInfoInput.weight"
            size="mini"
          >
            <template slot="append">kg</template>
          </el-input>
        </div>
      </div>
      <div class="item-row"></div>
    </div>

    <div style="padding: 7px 0">
      <el-divider content-position="left">手术信息</el-divider>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>
          手术类型
          <span style="margin: 0; color: red">*</span>
        </span>
        <cm-select
          class="item-content"
          activeUrl="/v1/webconsole/comboData/get/ssdj_ExamineName"
          :expand="false"
          v-model="patientInfoInput.examineType"
        ></cm-select>
      </div>
      <div class="item-row">
        <span>
          拟手术时间
          <span style="margin: 0; color: red">*</span>
        </span>
        <div class="item-content">
          <el-date-picker
            id="studyApplyTime"
            v-model="patientInfoInput.planStudyDate"
            type="datetime"
            align="center"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
          ></el-date-picker>
        </div>
      </div>
      <div class="item-row">
        <span>
          预计时长
          <span style="margin: 0; color: red">*</span>
        </span>
        <el-input
          class="input-form-item-content"
          type="text"
          v-model="patientInfoInput.planStudyDuration"
          size="mini"
        >
          <template slot="append">min</template>
        </el-input>
      </div>
      <div class="item-row">
        <span>
          手术名称
          <span style="margin: 0; color: red">*</span>
        </span>
        <div class="input-form-item-content">
          <el-select
            v-model="examineNameInfo"
            :clearable="true"
            multiple
            filterable
            default-first-option
            remote
            reserve-keyword
            size="mini"
            :remote-method="remoteOperation"
            placeholder="请选择"
            @change="changeSearchSurgeryOperation"
          >
            <el-option
              v-for="(item, index) in pubOperationList"
              :key="index"
              :label="item.dictName"
              :value="item.dictName"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="item-row">
        <span>临床症状</span>
        <cm-select
          class="item-content"
          activeUrl="/v1/webconsole/comboData/get/ssjbxx_ClinicalSymptoms"
          :expand="false"
          v-model="patientInfoInput.clinicalSymptoms"
        ></cm-select>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>手术类别</span>
        <cm-select
          class="item-content"
          activeUrl="/v1/webconsole/comboData/get/ssjbxx_Classification"
          :expand="false"
          v-model="patientInfoInput.classification"
        ></cm-select>
      </div>
      <div class="item-row">
        <span>切口类型</span>
        <cm-select
          class="item-content"
          activeUrl="/v1/webconsole/comboData/get/ssjbxx_Cuttype"
          :expand="false"
          v-model="patientInfoInput.cutType"
        ></cm-select>
      </div>
      <div class="item-row">
        <span>术前诊断</span>
        <cm-select
          class="item-content"
          v-model="patientInfoInput.preoperativeDiagnosis"
          activeUrl="/v1/webconsole/comboData/get/AO_preoperative_diagnosis"
        ></cm-select>
      </div>
      <div class="item-row">
        <span>合并症</span>
        <div>
          <el-input
            class="input-form-item-content"
            placeholder="请输入"
            type="text"
            v-model="patientInfoInput.complication"
            size="mini"
          ></el-input>
        </div>
      </div>
      <div class="item-row">
        <span>是否首次手术</span>
        <el-radio-group v-model="patientInfoInput.isFirstOperation" style="margin: 5px 0 5px 0">
          <el-radio label="是">是</el-radio>
          <el-radio label="否">否</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="top-item-row">
      <div class="item-row" v-if="patientInfoInput.isFirstOperation === '否'">
        <span>再次手术原因</span>
        <div>
          <el-input
            class="input-form-item-content"
            placeholder="请输入"
            type="text"
            v-model="patientInfoInput.reOperationReason"
            size="mini"
          ></el-input>
        </div>
      </div>
      <div v-else class="item-row"></div>
      <div class="item-row"></div>
      <div class="item-row"></div>
      <div class="item-row"></div>
      <div class="item-row"></div>
    </div>
    <!-- <div class="top-item-row">
      <div class="item-row">
        <span>再次手术原因</span>
        <div>
          <el-input class="input-form-item-content"
                    placeholder="请输入"
                    type="text"
                    v-model="patientInfoInput.reOperationReason"
                    size="mini">
          </el-input>
        </div>
      </div>
      <div class="item-row"></div>
      <div class="item-row"></div>
      <div class="item-row"></div>
    </div> -->
    <!-- <div class="top-item-row">
      <div class="item-row"
           style="width: 75.5%">
        <span>重要事项</span>
        <el-input class="input-form-item-textContent"
                  type="textarea"
                  v-model="patientInfoInput.importantItem"
                  size="mini"
                  :rows="3"
                  placeholder="请输入">
        </el-input>
      </div>
    </div> -->
    <div class="top-item-row">
      <div class="item-row">
        <span>
          主刀医生
          <span style="margin: 0; color: red">*</span>
        </span>
        <div class="item-content">
          <el-select v-model="examineDoctor" placeholder="请选择" @change="changeExamineDoctor">
            <el-option
              v-for="item in doctorList"
              :key="item.userId"
              :label="item.userName"
              :value="{ value: item.userId, label: item.userName }"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="item-row">
        <span>一助</span>
        <div class="item-content">
          <el-select
            v-model="assistantDoctor1"
            placeholder="请选择"
            @change="changeAssistantDoctor1"
          >
            <el-option
              v-for="item in doctorList"
              :key="item.userId"
              :label="item.userName"
              :value="{ value: item.userId, label: item.userName }"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="item-row">
        <span>二助</span>
        <div class="item-content">
          <el-select
            v-model="assistantDoctor2"
            placeholder="请选择"
            @change="changeAssistantDoctor2"
          >
            <el-option
              v-for="item in doctorList"
              :key="item.userId"
              :label="item.userName"
              :value="{ value: item.userId, label: item.userName }"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="item-row">
        <span>三助</span>
        <div class="item-content">
          <el-select
            v-model="assistantDoctor3"
            placeholder="请选择"
            @change="changeAssistantDoctor3"
          >
            <el-option
              v-for="item in doctorList"
              :key="item.userId"
              :label="item.userName"
              :value="{ value: item.userId, label: item.userName }"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="item-row">
        <span>巡回</span>
        <div class="item-content">
          <el-select
            v-model="tour"
            placeholder="请选择"
            multiple
            @change="changeTour"
            collapse-tags
          >
            <el-option
              v-for="item in assistantList"
              :key="item.userId"
              :label="item.userName"
              :value="{ value: item.userId, label: item.userName }"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="top-item-row">
      <div class="item-row">
        <span>护士</span>
        <div class="item-content">
          <el-select
            v-model="scrubNurse"
            placeholder="请选择"
            @change="changeScrubNurse"
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in assistantList"
              :key="item.userId"
              :label="item.userName"
              :value="{ value: item.userId, label: item.userName }"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="item-row">
        <span>技师</span>
        <div class="item-content">
          <el-select
            v-model="tech"
            placeholder="请选择"
            @change="changeScrubTech"
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in assistantList"
              :key="item.userId"
              :label="item.userName"
              :value="{ value: item.userId, label: item.userName }"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="item-row">
        <span>司械1</span>
        <div class="item-content">
          <el-select v-model="chargeEquipment1"
                     placeholder="请选择"
                     @change="changeChargeEquipment1">
            <el-option v-for="item in assistantList"
                       :key="item.userId"
                       :label="item.userName"
                       :value="{value:item.userId,label:item.userName}">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item-row">
        <span>司械2</span>
        <div class="item-content">
          <el-select v-model="chargeEquipment2"
                     placeholder="请选择"
                     @change="changeChargeEquipment2">
            <el-option v-for="item in assistantList"
                       :key="item.userId"
                       :label="item.userName"
                       :value="{value:item.userId,label:item.userName}">
            </el-option>
          </el-select>
        </div>
      </div> -->

      <div class="item-row">
        <span>麻醉方法</span>
        <cm-select
          class="input-form-item-content"
          v-model="patientInfoInput.anesthesiaMethod"
          activeUrl="/v1/webconsole/comboData/get/AO_anesthesia"
        ></cm-select>
      </div>
      <div class="item-row">
        <span>麻醉医生</span>
        <div class="item-content">
          <el-input
            class="input-form-item-content"
            type="text"
            v-model="patientInfoInput.anesthesiaDoctors"
            placeholder="请输入"
            size="mini"
          ></el-input>
        </div>
      </div>
      <div class="item-row">
        <span>麻醉会诊</span>
        <div>
          <el-input
            class="input-form-item-content"
            type="text"
            v-model="patientInfoInput.anesthesiaConsultation"
            placeholder="请输入"
            size="mini"
          ></el-input>
        </div>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../components/CmDialogBorder.vue'
import CmSelect from '../../../components/CmSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'

export default {
  name: 'PatientInfoInputDialog',
  components: {
    CmDialogBorder,
    CmSelect
  },
  data() {
    return {
      isShow: false,
      zIndex: PopupManager.nextZIndex(),
      department: localStorage.getItem('department'),
      examineDoctor: '',
      assistantDoctor1: '',
      assistantDoctor2: '',
      assistantDoctor3: '',
      scrubNurse: '',
      tech: '',
      chargeEquipment1: '',
      chargeEquipment2: '',
      tour: '',
      examineNameInfo: '',
      patientInfoInput: {
        patientsAge: '', //患者年龄
        physician: '', //医生列表
        tech: '', //技师列表
        nurse: '', //护士列表
        examineDoctor: '', //主刀医生
        assistantDoctor1: '', //一助
        assistantDoctor2: '', //二助
        assistantDoctor3: '', //三助
        anesthesiaDoctors: '', //麻醉医生列表
        scrubNurse: '', //洗手护士
        chargeEquipment1: '', //司械1
        chargeEquipment2: '', //司械2
        tour: '', //巡回
        examineNames: '', //手术名称
        examineList: [], //手术名称对象
        examineType: '', //手术类型
        classification: '', //手术类别
        clinicalSymptoms: '', //临床症状
        anesthesiaMethod: '', //麻醉方式
        anesthesiaConsultation: '', //麻醉会诊
        studyApplyTime: '', //手术申请时间 yyyy-MM-dd HH:mm  (科室申请手术时的日志时间, 这时候填写拟手术日期)
        planStudyDate: '', //拟手术日期 yyyy-MM-dd HH:mm
        planStudyDuration: '', //手术预计时长(单位min)
        isPlan: '', //是否计划(计划性/非计划性)
        isFirstOperation: '', //是否首次手术
        reOperationReason: '', //再次手术原因
        importantItem: '', //手术重要事项
        hbsag: '', //术前四项 乙肝
        hcv: '', //术前四项 丙肝
        hiv: '', //术前四项 艾滋
        rpr: '', //术前四项 梅毒
        clinicDiagnose: '', //临床诊断
        height: '', //身高
        weight: '', //体重
        insuranceType: '', //医保类型
        complication: '', //合并症
        cutType: '', //切口类型
        physicianId: '', //医生id列表
        techId: '', //技师id列表
        nurseId: '', //护士id列表
        examineDoctorId: '', //主刀医生id
        assistantDoctor1Id: '', //一助id
        assistantDoctor2Id: '', //一助id
        assistantDoctor3Id: '', //三助id
        chargeEquipment1Id: '', //司械1id
        chargeEquipment2Id: '', //司械2id
        scrubNurseId: '', //洗手护士id
        tourId: '', //巡回id
        patientsName: '', //患者姓名
        patientsID: '', //病案号
        patientsSex: '' //患者性别
      },
      assistantList: [],
      pubOperationList: []
    }
  },
  props: {
    doctorList: {
      type: Array,
      default: () => []
    },
    isToDetailPage: {
      type: String,
      default: () => ''
    }
  },
  mounted() {
    this.getAssistant()
    if (this.department == 'null') {
      this.department = undefined
    }
  },
  computed: {},
  methods: {
    changeSearchSurgeryOperation(val) {
      if (val) {
        let arr = []
        let arrID = []
        let arrList = []
        for (let i = 0; i < val.length; i++) {
          const element = val[i]
          for (let j = 0; j < this.pubOperationList.length; j++) {
            const ele = this.pubOperationList[j]
            if (element === ele.dictName) {
              arr.push(ele.dictName)
              arrID.push(ele.dictIndex)
              arrList.push({
                dictIndex: ele.dictIndex,
                dictName: ele.dictName
              })
            }
          }
        }
        this.patientInfoInput.examineIds = arrID.join('|')
        this.patientInfoInput.examineNames = arr.join('|')
        this.patientInfoInput.examineList = arrList
      } else {
        this.patientInfoInput.examineIds = ''
        this.patientInfoInput.examineNames = ''
      }
    },
    remoteOperation(query) {
      if (query !== '') {
        setTimeout(() => {
          this.getPubOperationName(query)
        }, 200)
      } else {
        this.pubOperationList = []
      }
    },
    getPubOperationName(query) {
      if (query) {
        this.queryGet = query
        this.operationUrl = `/v1/webconsole/study/puboperationlist?keyword=${this.queryGet}`
      } else {
        this.operationUrl = `/v1/webconsole/study/puboperationlist`
      }
      this.$api.get(this.operationUrl).then((res) => {
        let arr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            arr.push({ dictName: item.dictName, dictIndex: item.dictIndex })
          })
          this.pubOperationList = arr
          for (let i = 0; i < this.patientInfoInput?.examineList.length; i++) {
            const element = this.patientInfoInput.examineList[i]
            this.pubOperationList.push({
              dictName: element.dictName,
              dictIndex: element.dictIndex
            })
          }
        }
      })
    },
    formatUtcTime() {
      let date = new Date()
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
        ' ' +
        date.getHours() +
        ':' +
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':' +
        (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      )
    },

    getAssistant() {
      this.$api.get(`/v1/webconsole/study/nurses`).then((res) => {
        let arr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            arr.push({
              userName: item.userName,
              userId: item.userID
            })
          })
          this.assistantList = arr
        }
      })
    },
    changeExamineDoctor(val) {
      this.patientInfoInput.examineDoctor = val.label
      this.patientInfoInput.examineDoctorId = val.value
      this.$api.get(`/v1/webconsole/user/usergroups/${val.value}`).then((res) => {
        if (res.data.data?.length > 0) {
          res.data.data.map((item) => {
            switch (item.dutyCode) {
              case 'assistantDoctor1': {
                this.assistantDoctor1 = {
                  value: item.userId,
                  label: item.userName
                }
                this.changeAssistantDoctor1({
                  value: item.userId,
                  label: item.userName
                })
                break
              }
              case 'assistantDoctor2': {
                this.assistantDoctor2 = {
                  value: item.userId,
                  label: item.userName
                }
                this.changeAssistantDoctor2({
                  value: item.userId,
                  label: item.userName
                })
                break
              }
              case 'assistantDoctor3': {
                this.assistantDoctor3 = {
                  value: item.userId,
                  label: item.userName
                }
                this.changeAssistantDoctor3({
                  value: item.userId,
                  label: item.userName
                })
                break
              }
              case 'scrubNurse': {
                let arr = []
                item.userId?.split(',').map((items, index) => {
                  arr.push({
                    value: items,
                    label: item.userName?.split(',')[index]
                  })
                })
                this.scrubNurse = arr
                this.changeScrubNurse(arr)
                break
              }
              case 'tech': {
                let arr = []
                item.userId?.split(',').map((items, index) => {
                  arr.push({
                    value: items,
                    label: item.userName?.split(',')[index]
                  })
                })
                this.tech = arr
                this.changeScrubTech(arr)
                break
              }
              // case 'chargeEquipment1': {
              //   this.chargeEquipment1 = {
              //     value: item.userId,
              //     label: item.userName
              //   }
              //   this.changeChargeEquipment1({
              //     value: item.userId,
              //     label: item.userName
              //   })
              //   break
              // }
              // case 'chargeEquipment2': {
              //   this.chargeEquipment2 = {
              //     value: item.userId,
              //     label: item.userName
              //   }
              //   this.changeChargeEquipment2({
              //     value: item.userId,
              //     label: item.userName
              //   })
              //   break
              // }
              case 'tour': {
                let arr = []
                item.userId?.split(',').map((items, index) => {
                  arr.push({
                    value: items,
                    label: item.userName?.split(',')[index]
                  })
                })
                this.tour = arr
                this.changeTour(arr)
                break
              }
            }
          })
        } else {
          this.assistantDoctor1 = null
          this.changeAssistantDoctor1(null)
          this.assistantDoctor2 = null
          this.changeAssistantDoctor2(null)
          this.assistantDoctor3 = null
          this.changeAssistantDoctor3(null)
          this.scrubNurse = null
          this.changeScrubNurse(null)
          this.tech = null
          this.changeScrubTech(null)
          this.chargeEquipment1 = null
          this.changeChargeEquipment1(null)
          this.chargeEquipment2 = null
          this.changeChargeEquipment2(null)
          this.tour = null
          this.changeTour(null)
        }
      })
    },
    changeAssistantDoctor1(val) {
      this.patientInfoInput.assistantDoctor1 = val?.label
      this.patientInfoInput.assistantDoctor1Id = val?.value
    },
    changeAssistantDoctor2(val) {
      this.patientInfoInput.assistantDoctor2 = val?.label
      this.patientInfoInput.assistantDoctor2Id = val?.value
    },
    changeAssistantDoctor3(val) {
      this.patientInfoInput.assistantDoctor3 = val?.label
      this.patientInfoInput.assistantDoctor3Id = val?.value
    },
    // 护士
    changeScrubNurse(val) {
      let string1 = ''
      let string2 = ''
      val?.map((item, index) => {
        if (index === val?.length - 1) {
          string1 += item.label
          string2 += item.value
        } else {
          string1 += item.label + ','
          string2 += item.value + ','
        }
      })
      this.patientInfoInput.scrubNurse = string1
      this.patientInfoInput.scrubNurseId = string2
    },
    // 技师
    changeScrubTech(val) {
      let string1 = ''
      let string2 = ''
      val?.map((item, index) => {
        if (index === val?.length - 1) {
          string1 += item.label
          string2 += item.value
        } else {
          string1 += item.label + ','
          string2 += item.value + ','
        }
      })
      this.patientInfoInput.tech = string1
      this.patientInfoInput.techId = string2
    },
    changeChargeEquipment1(val) {
      this.patientInfoInput.chargeEquipment1 = val?.label
      this.patientInfoInput.chargeEquipment1Id = val?.value
    },
    changeChargeEquipment2(val) {
      this.patientInfoInput.chargeEquipment2 = val?.label
      this.patientInfoInput.chargeEquipment2Id = val?.value
    },
    // 巡回
    changeTour(val) {
      let string1 = ''
      let string2 = ''
      val?.map((item, index) => {
        if (index === val?.length - 1) {
          string1 += item.label
          string2 += item.value
        } else {
          string1 += item.label + ','
          string2 += item.value + ','
        }
      })
      this.patientInfoInput.tour = string1
      this.patientInfoInput.tourId = string2
    },
    Show() {
      this.patientInfoInput.studyApplyTime = this.formatUtcTime()
      this.getPubOperationName()
      this.isShow = true
    },
    open_save() {
      if (
        document.getElementById('patientsName').value == '' ||
        document.getElementById('studyApplyTime').value == '' ||
        this.patientInfoInput.patientsSex == '' ||
        this.patientInfoInput.examineType == '' ||
        this.patientInfoInput.examineDoctor == '' ||
        this.patientInfoInput.patientsID == '' ||
        this.examineNameInfo == ''
      ) {
        this.$message({
          message: '请完整输入姓名、性别、病案号、拟手术时间、手术名称、手术类型、主刀医生',
          type: 'warning'
        })
      } else {
        this.$confirm('是否保存患者登记信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.patientInfoInput.sickArea = this.department

            this.patientInfoInput.physician = this.patientInfoInput.examineDoctor
            this.patientInfoInput.physicianId = this.patientInfoInput.examineDoctorId

            if (this.patientInfoInput.assistantDoctor1) {
              if (this.patientInfoInput.nurse == '') {
                let arr = []
                arr.push(this.patientInfoInput.assistantDoctor1)
                this.patientInfoInput.nurse = arr.join('|')
              }
              if (this.patientInfoInput.nurseId == '') {
                let arr = []
                arr.push(this.patientInfoInput.assistantDoctor1Id)
                this.patientInfoInput.nurseId = arr.join('|')
              }
            }
            if (this.patientInfoInput.assistantDoctor2) {
              if (this.patientInfoInput.nurse == '') {
                let arr = []
                arr.push(this.patientInfoInput.assistantDoctor2)
                this.patientInfoInput.nurse = arr.join('|')
              } else {
                let arr = []
                arr.push(this.patientInfoInput.nurse, this.patientInfoInput.assistantDoctor2)
                this.patientInfoInput.nurse = arr.join('|')
              }

              if (this.patientInfoInput.nurseId == '') {
                let arr = []
                arr.push(this.patientInfoInput.assistantDoctor2Id)
                this.patientInfoInput.nurseId = arr.join('|')
              } else {
                let arr = []
                arr.push(this.patientInfoInput.nurseId, this.patientInfoInput.assistantDoctor2Id)
                this.patientInfoInput.nurseId = arr.join('|')
              }
            }
            if (this.patientInfoInput.assistantDoctor3) {
              if (this.patientInfoInput.nurse == '') {
                let arr = []
                arr.push(this.patientInfoInput.assistantDoctor3)
                this.patientInfoInput.nurse = arr.join('|')
              } else {
                let arr = []
                arr.push(this.patientInfoInput.nurse, this.patientInfoInput.assistantDoctor3)
                this.patientInfoInput.nurse = arr.join('|')
              }

              if (this.patientInfoInput.nurseId == '') {
                let arr = []
                arr.push(this.patientInfoInput.assistantDoctor3Id)
                this.patientInfoInput.nurseId = arr.join('|')
              } else {
                let arr = []
                arr.push(this.patientInfoInput.nurseId, this.patientInfoInput.assistantDoctor3Id)
                this.patientInfoInput.nurseId = arr.join('|')
              }
            }
            if (this.patientInfoInput.scrubNurse) {
              if (this.patientInfoInput.nurse == '') {
                let arr = []
                arr.push(this.patientInfoInput.scrubNurse)
                this.patientInfoInput.nurse = arr.join('|')
              } else {
                let arr = []
                arr.push(this.patientInfoInput.nurse, this.patientInfoInput.scrubNurse)
                this.patientInfoInput.nurse = arr.join('|')
              }

              if (this.patientInfoInput.nurseId == '') {
                let arr = []
                arr.push(this.patientInfoInput.scrubNurseId)
                this.patientInfoInput.nurseId = arr.join('|')
              } else {
                let arr = []
                arr.push(this.patientInfoInput.nurseId, this.patientInfoInput.scrubNurseId)
                this.patientInfoInput.nurseId = arr.join('|')
              }
            }
            this.Save()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消保存'
            })
          })
      }
    },
    Save() {
      delete this.patientInfoInput.examineList
      this.$api.post('/v1/webconsole/study/register', { ...this.patientInfoInput }).then(
        (res) => {
          if (res.data.status === 200) {
            this.$message.success('登记成功')
            this.patientInfoInput = {}
            this.examineNameInfo = ''
            this.examineDoctor = ''
            this.assistantDoctor1 = ''
            this.assistantDoctor2 = ''
            this.assistantDoctor3 = ''
            this.scrubNurse = ''
            this.chargeEquipment1 = ''
            this.chargeEquipment2 = ''
            this.tour = ''
            this.isShow = false
            this.$emit('save')
            if (res.data.data.uuid) {
              setTimeout(() => {
                localStorage.setItem('patientInfo', JSON.stringify(res.data.data))
                if (!this.isToDetailPage) {
                  const { href } = this.$router.resolve({
                    path: `/Detail/BaseInfo?operationId=${res.data.data.uuid}`
                  })
                  window.open(href, '_blank')
                }
              }, 500)
            }
          } else if (res.data.status === 500) {
            this.$message.warning(res.data.msg)
            this.isShow = false
          }
        },
        () => this.$message.error('登记失败')
      )
    },
    Close() {
      this.isShow = false
      this.patientInfoInput = {}
      this.examineNameInfo = ''
      this.examineDoctor = ''
      this.assistantDoctor1 = ''
      this.assistantDoctor2 = ''
      this.assistantDoctor3 = ''
      this.scrubNurse = ''
      this.chargeEquipment1 = ''
      this.chargeEquipment2 = ''
      this.tour = ''
    }
  }
}
</script>
