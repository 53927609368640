// import 'core-js/stable'
// import 'regenerator-runtime/runtime'
// import Es6Promise from 'es6-promise'
// require('es6-promise').polyfill()
// Es6Promise.polyfill()

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import api from './assets/js/api'
import store from './store'
import './plugins/direction.js'
import './plugins/hammer.min.js'
import './assets/css/common.less'
import './assets/css/image-button.less'
import './assets/font/iconfont.css'
import './assets/icon-font/iconfont.css'
import './utils/rem.js'
// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css'
import axios from 'axios'
import * as cornerstone from 'cornerstone-core'
import * as dicomParser from 'dicom-parser'
import * as cornerstoneTools from 'cornerstone-tools'
import * as cornerstoneMath from 'cornerstone-math'
import cornerstoneWADOImageLoader from './plugins/cornerstoneWADOImageLoader.js'
import { SERVER_HOST } from '@/utils/globalVariable'
import '../src/assets/js/directive.js' // 拖拽弹窗，在需要用到拖拽功能的弹窗标签上加v-dialogDrag
import vueEsign from 'vue-esign'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import qs from 'qs'
import dayjs from 'dayjs'
import VDistpicker from 'v-distpicker'
import Message from './components/ai-monitor-center/ai-messageExtend.js'
Vue.prototype.$MessageAi = Message

Vue.component('v-distpicker', VDistpicker)
Vue.use(vueEsign)

axios.defaults.withCredentials = true
axios.defaults.baseURL = SERVER_HOST
axios.defaults.timeout = 60000
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$XLSX = XLSX //设置全局
Vue.prototype.$FileSaver = FileSaver //设置全局
cornerstoneTools.external.cornerstone = cornerstone
cornerstoneTools.external.cornerstoneMath = cornerstoneMath
cornerstoneWADOImageLoader.external.cornerstone = cornerstone
cornerstoneWADOImageLoader.external.dicomParser = dicomParser
cornerstoneWADOImageLoader.external.cornerstoneMath = cornerstoneMath

/*
 *配置 webWorker (必须配置)
 */
cornerstoneWADOImageLoader.configure({
  beforeSend: function () {
    // Add custom headers here (e.g. auth tokens)
    //xhr.setRequestHeader('APIKEY', 'my auth token');
  }
})
var config = {
  maxWebWorkers: navigator.hardwareConcurrency || 1,
  startWebWorkersOnDemand: true,
  // webWorkerPath: ' ../../ dist / cornerstoneWADOImageLoaderWebWorker.js ',
  taskConfiguration: {
    decodeTask: {
      initializeCodecsOnStartup: false
      // codecsPath : ' ../ dist / cornerstoneWADOImageLoaderCodecs.js ',
    },
    sleepTask: {
      sleepTime: 3000
    }
  }
}
cornerstoneWADOImageLoader.webWorkerManager.initialize(config)

cornerstoneTools.init({
  mouseEnabled: true,
  touchEnabled: true,
  globalToolSyncEnabled: true,
  showSVGCursors: false
})

cornerstoneTools.toolColors.setToolColor('yellow')

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$store = store
Vue.prototype.$cornerstone = cornerstone
Vue.prototype.$dicomParser = dicomParser
Vue.prototype.$cornerstoneTools = cornerstoneTools
Vue.prototype.$cornerstoneWADOImageLoader = cornerstoneWADOImageLoader

Vue.prototype.$http = axios
Vue.prototype.$echarts = echarts
Vue.prototype.axios = axios
Vue.config.productionTip = false

Vue.prototype.$qs = qs
Vue.prototype.dayjs = dayjs

// 事件总线
let EventBus = new Vue()
Vue.prototype.$bus = EventBus

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
