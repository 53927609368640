import axios from 'axios'
// 引入进度条样式
import { Message, MessageBox } from 'element-ui'

const config = {
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  withCredentials: true
}
const { confirm } = MessageBox

const service = axios.create(config)
service.interceptors.request.use(
  (config) => {
    if (config.url.indexOf('getDicom') > -1) {
      config.timeout = 60000
    }
    return config
  },
  (error) => {
    Message({
      showClose: true,
      message: error,
      type: 'warning'
    })
    return Promise.reject(error)
  }
)

let lastErrorTime = 0 // 记录最后一次错误提示的时间戳

service.interceptors.response.use(
  (response) => {
    const handleError = (msg, type) => {
      if (!response.config.errorHandled || Date.now() - lastErrorTime > 5000) {
        response.config.errorHandled = true
        lastErrorTime = Date.now()
        if (type === 'confirm') {
          confirm(msg, '提示', {
            confirmButtonText: '确定',
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            type: 'error'
          }).then(() => {
            var url = 'http://' + location.host + '/#/'
            window.open(url, '_self')
          })
        } else {
          Message({
            showClose: true,
            message: msg,
            type: type === 'warning' ? 'warning' : 'error'
          })
        }
      }
    }
    if (response.status === 200) {
      if (response.data.status && response.data.status === 401) {
        handleError(response.data.msg, 'confirm')
        return Promise.reject(response)
      } else if (response.data.status && response.data.status !== 200) {
        handleError(response.data.msg, 'warning')
        return Promise.reject(response)
      } else {
        return Promise.resolve(response)
      }
    } else if (response.status === 403) {
      handleError('请重新登录', 'confirm')
      return Promise.reject(response)
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    Message.closeAll()
    Message({
      showClose: true,
      message: `网络错误: 03 (${error})`,
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export default service
