<style scoped lang="less">
.button-panel {
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: flex-start;
}
.item-row {
  margin: 1rem 1rem 1rem 3rem;
  display: flex;
}
.item-row > span {
  width: 40%;
  margin: 5px 20px 5px 0;
}
.item-content {
  width: 280px;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="手术时间安排" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span class="form-item-label">计划手术时间</span>
        <div class="item-content" style="display: flex">
          <el-date-picker
            v-model="planStudyDay"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            align="center"
          ></el-date-picker>
          <el-time-picker
            v-model="planStudyTime"
            format="HH:mm"
            value-format="HH:mm"
          ></el-time-picker>
        </div>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span class="">次序</span>
        <div class="item-content">
          <el-input type="text" v-model="schedulingInfo.doctorNumber" size="mini"></el-input>
        </div>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'

export default {
  name: 'SchedulingDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      operationId: '',
      planStudyDay: '',
      planStudyTime: '00:00',
      schedulingInfo: {
        planStudyDate: '',
        doctorNumber: ''
      }
    }
  },
  methods: {
    formatUtcDate(v) {
      let date = v ? new Date(v) : new Date()
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
      )
    },
    formatUtcTime(v) {
      if (v) {
        let date = new Date(v)
        return (
          date.getHours() +
          ':' +
          (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
        )
      } else {
        return '00:00'
      }
    },
    Show(bloodVessel) {
      this.operationId = bloodVessel.uuid
      this.planStudyDay = this.formatUtcDate(bloodVessel.planStudyDate)
      this.planStudyTime = this.formatUtcTime(bloodVessel.planStudyDate)
      this.schedulingInfo.doctorNumber = bloodVessel.doctorNumber
      this.isShow = true
    },
    Save() {
      if (!this.planStudyDay) {
        return this.$message.warning('计划手术时间不能为空！')
      }
      if (!this.schedulingInfo.doctorNumber) {
        return this.$message.warning('台次不能为空！')
      }
      this.schedulingInfo.planStudyDate = this.planStudyDay + ' ' + this.planStudyTime
      let data = {
        planStudyDate: this.schedulingInfo.planStudyDate || null,
        doctorNumber: this.schedulingInfo.doctorNumber || null
      }

      let formData = new FormData()
      for (let i in data) {
        const element = data[i]
        if (element !== null) {
          formData.append(i, element)
        }
      }
      this.$api
        .post(`/v1/webconsole/study/schedule/doctor/update/number/${this.operationId}`, formData)
        .then((res) => {
          if (res.data && res.data.data) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$emit('save')
          } else {
            this.$message({
              message: res.data.msg,
              type: 'warning'
            })
          }
          this.isShow = false
        })
        .finally(() => {
          this.schedulingInfo = {
            planStudyDate: '',
            doctorNumber: ''
          }
        })
    },

    Close() {
      this.isShow = false
      this.schedulingInfo = {
        planStudyDate: '',
        doctorNumber: ''
      }
    }
  }
}
</script>
